/* eslint-disable */ 
import * as React from 'react';
//import Main from './Main';
import Main from './MainPC';
import { BrowserView, MobileView } from 'react-device-detect';
import MainMobile from './MainMobile';

export default function MainLanding(prop) {
    let [mbView, setMbView] = React.useState(false);
    React.useEffect(() => {
      checkWidth()
  }, []);
    window.addEventListener('resize', ()=> {
      checkWidth()
    })
    function checkWidth() {
      if (window.innerWidth > 1024 && mbView)
      {
        setMbView(false);
        window.location.reload(true);

      }
      else if (window.innerWidth <= 1024 && !mbView) {
        setMbView(true);
      }
    }
    return (
        <>
      { /* web */ }
      <BrowserView>
        {mbView ?
        <MainMobile lang={prop.lang}></MainMobile>
        :
        <Main lang={prop.lang}></Main>
        }   
      </BrowserView>
      { /* mobile */ }
      <MobileView>
        <MainMobile lang={prop.lang}></MainMobile>
      </MobileView>
      </>
        
    )
}