import React, { useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import Footer from '../common/Footer';
import $ from 'jquery';



export default function Solution(prop) {
    let lang = prop.lang;

    useEffect(() => {
        let page = window.location.search.split("=")[1];
        if (page) {
            window.scrollTo(0,$('.'+page).offset().top - $('.main-header').height());
        }
        else {
            window.scrollTo(0, 0);
        }

        $(window).on('scroll', (e)=>{
            let st = $(window).scrollTop();
            let wh = $(window).innerHeight();

            $('.js-fadeIn').each(function() {
                let this_top = $(this).offset().top;
                if (st+wh > this_top) {
                    $(this).addClass('on');
                }
            })
        })
        $(window).trigger('scroll');
        return () => {
            $(window).off('scroll');
        }

    }, []);

    return ( 
        <>
            <div id="container" className={lang}>
                <div id="contents" className="solution">
                    <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    <span>SOLUTION</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <section className="section full-section solution_intro"> 
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header ">
                                        {lang==="kr" ?  
                                        <p className="tit point_circle">
                                            <span>모픽의 솔루션은 <br />다른 공간 · 다른 차원 · 다른 세계를 <br />사실적으로 연결합니다 </span>
                                        </p>
                                        : 
                                        <p className="tit point_circle">
                                            <span>MOPIC's solutions  realistically connect<br />different spaces, dimensions, and worlds </span>
                                        </p>
                                        }
                                    </div>
                                    <div className="panel-body">
                                        {lang==="kr" ?  
                                        <p className="txt">모픽 라이트필드 3D 솔루션은 3차원 그래픽 공간에 그려진 3D 그래픽을 실제 존재하는 공간을 바라보듯 디스플레이를 통해 현실감 있게 볼 수 있게 합니다. 3D 카메라로 촬영한 영상이 있다면, 양안 시차와 컨버전스를 최적 값으로 조절하는 소프트웨어 기술로 영상의 깊이를 실제와 매우 유사하게 보여줍니다. 공간감이 느껴지는 3D 디지털 아트로 색다른 영감을 주거나, 완전히 몰입하게 만드는 광고 효과를 발생시킬 수도 있습니다. </p>
                                        : 
                                        <p className="txt">Our Light Field 3D solution enables 3D-drawn graphics to be viewed as realistic-looking 3D images through a display as if customers were looking at real-life spaces. Our technology takes images taken by 3D cameras and optimally adjusts them to allow the viewer to feel as if they are looking at real images in front of them - without the need for glasses. Digital art with a sense of 3D space can inspire companies to create ground-breaking advertising effects that makes customers feel completely immersed.</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section basic-section page-solution page0 js-fadeIn">
                        <div className="section-inner">
                                <div className="panel-header">
                                <StickyBox offsetTop={120}>
                                    {lang==="kr" ?  
                                    <p className="tit point_circle">3차원 그래픽을 3차원 공간 그대로 시청할 수 있습니다</p>
                                    :
                                    <p className="tit point_circle">Customers can vividly watch 3D graphics that are shown in 3D spaces</p>
                                    }
                                    {lang==="kr" ? 
                                    <p className="txt">
                                    3차원 공간에 그려진 3D 그래픽을 더 이상 2차원 평면에 가두지 마세요! 2D 디스플레이는 3차원 그래픽 공간을 2차원으로 압착해서 보여줍니다.  <br />
                                    모픽은 ‘3D 그래픽’을 최적의 입체영상으로 변환하고, 라이트필드 3D 디스플레이를 통해 ‘3차원 공간 그대로’ 보여주는 솔루션을 제공합니다.  <br />
                                    사용자의 시청위치를 반영해 선명한 입체감을 제공하는 모픽의 Real-time Rendering기술이 적용되어, 사용자의 움직임에도 일관적인 몰입감을 제공합니다. 
                                    </p>
                                    :
                                    <p className="txt">
                                    We go beyond the limits of 3D graphics drawn in 3D spaces on a 2D flat screen.  <br />
                                    Any 2D display can now easily show 3D images right in front of you. MOPIC allows the viewing of three-dimensional spaces by converting 3D graphics into optimal stereoscopic images combined with our 3D Light Field displays.  <br />
                                    MOPIC’s real-time rendering technology tracks the user’s viewing position to give the most optimal 3D depth perception, even when that user is moving.
                                    </p>
                                    }
                                    <div className="btn_wrap">
                                        <div>
                                            <Link to={"/"+lang+"/casestudy/list"} className='btn_arrow'>Case Study <i className="ic_arrow_link"></i></Link>
                                        </div>
                                        <div>
                                            <Link to={"/"+lang+"/lightfield"} className='btn_arrow'>How it works<i className="ic_arrow_link"></i></Link>
                                        </div>
                                        <div>
                                            {lang==="kr" ? 
                                            <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>솔루션 문의<i className="ic_arrow_link"></i></Link>
                                            :
                                            <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>Solution Inquiries<i className="ic_arrow_link"></i></Link>
                                            }
                                        </div>
                                    </div>
                                    </StickyBox>
                                </div>
                                
                                <div className="panel-body">
                                    <ul className="scroll_imgs">
                                        <li className="img_item pos1">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_0_0.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">게임</p>
                                                :
                                                <p className="tit">Game</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos0">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_0_1.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">3D 모델</p>
                                                :
                                                <p className="tit">3D Model</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos2">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_0_2.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">건축 디자인</p>
                                                :
                                                <p className="tit">Architectural Design</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos1">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_0_3.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">시뮬레이션</p>
                                                :
                                                <p className="tit">Simulation</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos0">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_0_4.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">분자구조</p>
                                                :
                                                <p className="tit">Molecular Structure</p>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </section>

                    <section className="section basic-section page-solution page1 js-fadeIn">
                        <div className="section-inner">

                            
                                <div className="panel-header">
                                <StickyBox offsetTop={120}>
                                    {lang==="kr" ?  
                                    <p className="tit point_circle">떨어진 다른 공간을 사실적으로 연결합니다.  </p>
                                    :
                                    <p className="tit point_circle">Realistically connect to others, no matter how far</p>
                                    }
                                    {lang==="kr" ?  
                                    <p className="txt">
                                        도처에 위험물이 도사리고 있는, 혹은 너무 덥거나 추운 환경으로부터 작업자들을 멀어지게 할 수 있을까요?  
                                        화상통화를 하고 있지만, 상대방이 눈 앞에 앉아있는 것 같은 실재감을 느끼게 할 수 있을까요? <br />
                                        모픽은 두 눈으로 보는 시야 그대로를 3D 카메라로 촬영하고, 라이트필드 3D 디스플레이를 통해 ‘입체감, 거리감, 깊이감’을 보여주는 솔루션을 제공합니다. <br /> 카메라의 촬영 조건과 사용자의 시청 환경을 일치시켜 실제와 유사한 거리감을 제공하는 MOPIC Hub 기술로 장비 조종이나 수술 같은 세밀한 작업까지 화면을 보면서 가능하도록 합니다. 
                                    </p>
                                    :
                                    <p className="txt">
                                        Is it possible to keep workers out of dangerous sites or from extreme temperature environments?  <br />
                                        Is it possible to create a sense of reality as if they are talking face-to-face during video calls? <br />
                                        MOPIC offers solutions that take pictures with 3D cameras as seen with the naked eye to show three-dimensional effects with a sense of distance and depth through our 3D Light Field displays. MOPIC Hub technology, which matches the camera's shooting conditions and the user's viewing environment, provides a sense of distance that is life-like, enabling detailed tasks such as equipment control and surgery to be performed remotely while viewing the screen.
                                    </p>
                                    }
                                    <div className="btn_wrap">
                                        <div>
                                            <Link to={"/"+lang+"/casestudy/list"} className='btn_arrow'>Case Study <i className="ic_arrow_link"></i></Link>
                                        </div>
                                        <div>
                                            <Link to={"/"+lang+"/lightfield"} className='btn_arrow'>How it works<i className="ic_arrow_link"></i></Link>
                                        </div>
                                        <div>
                                            
                                            {lang==="kr" ? 
                                            <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>솔루션 문의<i className="ic_arrow_link"></i></Link>
                                            :
                                            <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>Solution Inquiries<i className="ic_arrow_link"></i></Link>
                                            }
                                        </div>
                                    </div>
                                    </StickyBox>
                                </div>
                                
                                <div className="panel-body">
                                    <ul className="scroll_imgs">
                                        <li className="img_item pos1">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_1_0.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">내시경 수술</p>
                                                :
                                                <p className="tit">Endoscopic surgery</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos0">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_1_1.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">디지털 현미경</p>
                                                :
                                                <p className="tit">Digital microscope</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos2">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_1_2.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">원격 모니터링</p>
                                                :
                                                <p className="tit">Remote monitoring</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos1">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_1_3.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">자동차 카메라</p>
                                                :
                                                <p className="tit">Car camera</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos0">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_1_4.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">화상통화</p>
                                                :
                                                <p className="tit">Video call</p>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </section>

                    <section className="section basic-section page-solution page2 js-fadeIn">
                        <div className="section-inner">

                            
                                <div className="panel-header">
                                <StickyBox offsetTop={120}>
                                    {lang==="kr" ?  
                                    <p className="tit point_circle">다수의 시선을 사로잡는 새로운 광고 전략! </p>
                                    :
                                    <p className="tit point_circle">A new advertising strategy that catches people’s attention</p>
                                    }
                                    {lang==="kr" ?  
                                    <p className="txt">
                                        고객이 몰입하게 만드는 광고 전략이 필요한가요? 화면을 보는 위치에 따라 다른 이미지가 보이는 홀로그램 효과를 추가하거나, 화면 안으로 공간이 펼쳐지는 것 같은 깊이감과 입체감을 디지털 사이니지에 추가해 보세요! <br />
                                        모픽의 라이트필드 3D 솔루션은 일반 디지털 사이니지와 전혀 다르지 않는 외관에, 차원이 다른 시각적 경험을 제공합니다. 고객의 시선을 메신저가 아닌 메시지(콘텐츠)에 주목시키는 힘이 있습니다.  
                                    </p>
                                    :
                                    <p className="txt">
                                        Do you need an advertising strategy to immediately get your customers’s attention? Add hologram effects that show different pictures depending on where you look at the screen or add a sense of depth and three-dimension for life-like ads! <br />
                                        MOPIC’s Light Field 3D solution provides different visual experiences, creating life-like appearances on ordinary digital signs. Allow your customers to powerfully see your eye-popping content.
                                    </p>
                                    }
                                    <div className="btn_wrap">
                                        <div>
                                            <Link to={"/"+lang+"/casestudy/list"} className='btn_arrow'>Case Study <i className="ic_arrow_link"></i></Link>
                                        </div>
                                        <div>
                                            <Link to={"/"+lang+"/lightfield"} className='btn_arrow'>How it works<i className="ic_arrow_link"></i></Link>
                                        </div>
                                        <div>
                                            {lang==="kr" ? 
                                            <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>솔루션 문의<i className="ic_arrow_link"></i></Link>
                                            :
                                            <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>Solution Inquiries<i className="ic_arrow_link"></i></Link>
                                            }
                                        </div>
                                    </div>
                                    </StickyBox>
                                </div>
                                
                                <div className="panel-body">
                                    <ul className="scroll_imgs">
                                        <li className="img_item pos1">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_2_0.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ?  
                                                <p className="tit">광고</p>
                                                :
                                                <p className="tit">Advertising</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos0">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_2_1.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ? 
                                                <p className="tit">키오스크</p>
                                                :
                                                <p className="tit">Kiosk</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos2">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_2_2.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ? 
                                                <p className="tit">박물관</p>
                                                :
                                                <p className="tit">Museum</p>
                                                }
                                            </div>
                                        </li>
                                        <li className="img_item pos1">
                                            <div className="item_inner">
                                                <div className="img_wrap">
                                                    <img src="/resources/images/sub/solution_item_2_3.jpg" alt="" />
                                                </div>
                                                {lang==="kr" ? 
                                                <p className="tit">테마파크</p>
                                                :
                                                <p className="tit">Theme Park</p>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </section>

                </div>
            </div>
            
            <Footer lang={lang}></Footer>
        </>
    ); 
}; 