import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import TechHeader from "./TechHeader";
import { Col, Grid, Input, Row, Radio, RadioGroup } from "rsuite";
import "../../scss/tech.scss";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "rsuite/dist/rsuite.min.css";
import { set } from "react-ga";
import APIURI from "../modules/APIURI";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "../modules/Login";

export default function TechWrite(prop) {
  let lang = prop.lang ? prop.lang : "en";
  let params = useParams();
  let sid = params.id ? params.id : "";

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelectorAll(".header_wrap").forEach(function (element) {
      element.style.display = "none";
    });
  }, [params]);
  let [content, setContent] = useState("");
  let [errorcode, setErrorcode] = useState("");
  let [title, setTitle] = useState("");
  let [model, setModel] = useState("");
  let [category, setCategory] = useState("");
  let [language, setLanguage] = useState("en");
  let [pre_content, setPreContent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("sid", sid);
    if (sid) {
      getData();
    }

    return () => {};
  }, []);
  function getData() {
    //let _url = "/resources/tech/" + lang + "/" + sid + ".json";
    let _url = APIURI + "tech/" + lang + "/1/?pid=" + sid;
    //let _url = "https://api.mopiclabs.com/board/tech/" + lang + "/1/?pid=" + sid;

    axios.get(_url, {}).then(function (response) {
      setTitle(response.data.post_title);
      //setDate(response.data.regist_date.split("T")[0]);
      setContent(response.data.post_contents);
      setErrorcode(response.data.post_errorcode.join(","));
      setCategory(response.data.post_category.join(","));
      setModel(response.data.post_model);
      console.log(response.data);
    });
  }

  function sendData() {
    if (sid) {
      let _data = {
        post_id: sid,
        post_title: title.replaceAll("'", "&#39;"),
        post_category: category,
        post_model: model,
        post_error_code: errorcode,
        post_contents: content.replaceAll("'", "&#39;"), //editor.getHTML().replaceAll("'","&#39;"),
      };

      axios({
        method: "put",
        //url: APIURI + type.toLowerCase() + "/" + lang.toLowerCase(),
        url: APIURI + "tech/" + lang + "/",
        data: _data,
      }).then(function (response) {
        //console.log(response.data);
        if (response.data.result === "FAIL") {
        } else {
          // navigate("/" + lang.toLowerCase() + "/" + prop.type.toLowerCase() + "/list");
        }
      });
    } else {
      (async () => {
        try {
          let _data = {
            post_title: title.replaceAll("'", "&#39;"),
            post_category: category.replaceAll(" ", ""),
            post_model: model.replaceAll(" ", ""),
            post_errorcode: errorcode.replaceAll(" ", ""),
            post_contents: content.replaceAll("'", "&#39;"), //editor.getHTML().replaceAll("'","&#39;"),
          };

          axios({
            method: "post",
            //url: APIURI + type.toLowerCase() + "/" + lang.toLowerCase(),
            url: APIURI + "tech/" + lang + "/",
            data: _data,
          }).then(function (response) {
            if (response.data.result === "FAIL") {
            } else {
              //navigate('/'+lang.toLowerCase()+'/'+prop.type.toLowerCase() + '/list');
              alert("저장되었습니다.");
            }
          });
        } catch (error) {
          console.error("Error in processImages:", error);
        }
      })();
    }
  }

  return (
    <>
      <div id="container">
        <TechHeader lang={lang} />
        <div id="contents" className="tech_solution view_page">
          <div className="tech_inner">
            <p className="tech_title">Technical Solution</p>

            <AuthenticatedTemplate>
              <div className="view_wrap write_mode">
                <div className="view_title">
                  <Grid fluid>
                    <Row className="show-grid">
                      <Col xs={4}>
                        <span className="title">언어</span>
                      </Col>
                      <Col xs={20}>
                        <span className="title">
                          {lang == "en" ? "English" : "Korean"}
                        </span>
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={4}>
                        <span className="title">제목</span>
                      </Col>
                      <Col xs={20}>
                        <Input
                          type="text"
                          id="title"
                          size="md"
                          value={title}
                          defaultValue={title}
                          onChange={(value) => {
                            setTitle(value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={4}>
                        <span className="title">Model</span>
                      </Col>
                      <Col xs={20}>
                        <Input
                          type="text"
                          id="model"
                          size="md"
                          value={model}
                          defaultValue={model}
                          placeholder="Common || U65A730"
                          onChange={(value) => {
                            setModel(value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={4}>
                        <span className="title">Category</span>
                      </Col>
                      <Col xs={20}>
                        <Input
                          type="text"
                          id="category"
                          size="md"
                          value={category}
                          defaultValue={category}
                          placeholder="Program,Eye-tracking,etc"
                          onChange={(value) => {
                            setCategory(value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={4}>
                        <span className="title">Error Code</span>
                      </Col>
                      <Col xs={20}>
                        <Input
                          type="text"
                          id="errorcode"
                          size="md"
                          value={errorcode}
                          defaultValue={errorcode}
                          placeholder="0x01,0x02,etc"
                          onChange={(value) => {
                            setErrorcode(value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="show-grid">
                      <Col xs={24}>
                        <Input
                          as="textarea"
                          rows={20}
                          size="md"
                          defaultValue={content}
                          placeholder="내용 (HTML 태그 입력)"
                          onChange={(value) => {
                            setContent(value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Grid>
                </div>
                <div
                  className="view_cont"
                  dangerouslySetInnerHTML={{ __html: pre_content }}></div>

                <div className="view_footer">
                  <div className="view_btn_wrap">
                    <button
                      className="btn btn_blue btn_sm"
                      onClick={(e) => {
                        setPreContent(content);
                      }}>
                      미리보기
                    </button>
                    <button
                      className="btn btn_blue btn_sm"
                      onClick={(e) => {
                        sendData();
                      }}>
                      저장하기
                    </button>
                  </div>
                </div>
              </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
          </div>
        </div>
      </div>
      <Footer lang={lang} className="mt0"></Footer>
    </>
  );
}
