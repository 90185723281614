/* eslint-disable */ 
import React, { useEffect, useState } from 'react';
import Footer from '../common/Footer';
import { Link, useNavigate , useParams } from 'react-router-dom';
import axios from 'axios';
import APIURI from './APIURI';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";


export default function ContentsView(prop) {
    let params = useParams();
    let type = prop.type.split('_')[0];
    let lang = prop.lang;
    let admin = prop.type.indexOf("ADMIN") > -1;

    let navigate = useNavigate();
    let sid ;

    useEffect(() => {
        
        loadData() 
        return () => {
            
        }
    }, []);
    
    function loadData(id) {
        
        let _url = APIURI+type.toLowerCase()+'/'+lang.toLowerCase()+'/1/'
        sid = id ? id : params.id ;
        
        axios.get(_url, {
            params: {
                pid : sid
            }
        })
        .then(function (response) {
            window.scrollTo(0, 0);
            //console.log(response.data[0]);
            var _date = new Date(response.data[0].regist_date);
            response.data[0].regist_date = _date.getFullYear() + "-" + toDigit(_date.getMonth()+1) + "-"+ toDigit(_date.getDate());
            //setViewData(response.data[0]);


            setView_title(response.data[0].post_title);
            setView_contents(response.data[0].post_contents);
            setView_tags(response.data[0].post_tags);
            setView_category(response.data[0].post);
            setView_previd(response.data[0].prev_pid);
            setView_nextid(response.data[0].next_pid);
            setView_date(response.data[0].regist_date);
        })
    }

    function toDigit(num) {
        return (num<10) ? "0"+num: num;
    }
    
    let [viewData, setViewData] = useState({});
    let [view_title, setView_title] = useState('');
    let [view_contents, setView_contents] = useState('');
    let [view_tags, setView_tags] = useState('');
    let [view_category, setView_category] = useState('');
    let [view_previd, setView_previd] = useState('');
    let [view_nextid, setView_nextid] = useState('');
    let [view_date, setView_date] = useState('');

    function b64toBlob(b64Data, sliceSize=512) {
        const byteCharacters = atob(b64Data.replace(/^data:image\/[a-z]+;base64,/, ''));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/png' });
        return blob;
    }
    const downloadImage = async (image, id) => {
        const res = await axios.get(APIURI+'image/?id='+ id);
        //image.attr('src', res.data.image);
        
        const url = window.URL.createObjectURL(b64toBlob(res.data.image));
        image.src=url;
    }
    useEffect(() => {
        const cont_box = document.getElementById('cont_box');
        if (cont_box) {
            const images = cont_box.querySelectorAll('img');
            for (const img of images) {
                if (img.src.startsWith('blob:')) {
                    let id = img.src.replace('blob:', '');
                    console.log("!!!", id);
                    downloadImage(img, id);
                }
            }
        }
        
    }, [view_contents]);

    function goPrevView() {
        if (view_previd) {
            navigate('/'+ lang.toLowerCase() + '/'+prop.type.toLowerCase()+'/view/'+view_previd);
            loadData(view_previd);
        }
        return false;
    }
    function goNextView() {
        if (view_nextid) {
            navigate('/'+ lang.toLowerCase() + '/'+prop.type.toLowerCase()+'/view/'+view_nextid);
            loadData(view_nextid);
        }
        return false;
    }
    function goList() {
        navigate('/'+ lang.toLowerCase() + '/'+prop.type.toLowerCase()+'/list');
        return false;
    }
    
    function editView() {
        navigate('/'+ lang.toLowerCase() + '/'+prop.type.toLowerCase()+'/write/'+params.id);
        return false;
    }
    function deleteView() {
        let _url = APIURI+type.toLowerCase()+'/'+lang.toLowerCase();
        //console.log(_url, params.id);
        axios({
            method: 'DELETE',
            url: _url,
            data : {
                post_id : params.id
            }
        })
        .then(function (response) {
            //console.log(response);
            navigate('/'+lang.toLowerCase()+'/'+prop.type.toLowerCase() + '/list');
        });
    }
    
    const decodeHtmlEntities = (html) => {
        const parser = new DOMParser();
        const decoded = parser.parseFromString(html, 'text/html').body.textContent;
        return decoded;
    };
    return ( 
        <>
            <div id="container" >
                <div id="contents" className={ type.toLowerCase() }>
                    <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    { type==="NEWS" || type==="NEWS_ADMIN" ?
                                        <span>NEWS</span>
                                        : null
                                    }
                                    { type==="CASESTUDY" || type==="CASESTUDY_ADMIN" ?
                                        <span>CASE STUDY</span>
                                        : null
                                    }
                                    { type==="SUPPORT" || type==="SUPPORT_ADMIN" ?
                                        <span>SUPPORT</span>
                                        : null
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    {view_title ? 
                    <section className="section basic-section view_container">
                        <div className="section-inner">
                            <div className="view_header">
                                {view_category ? 
                                <p className="category">{view_category}</p>
                                : null
                                }
                                
                                <p className="title">{decodeHtmlEntities(view_title)}</p>
                                <p className="date">{ view_date }</p>
                            </div>
                            <div className="view_body">
                                <div id="cont_box" className="view_inner" dangerouslySetInnerHTML={ {__html: view_contents} }>
                                </div>
                                { view_tags ?
                                    <div className="tag_box">
                                    {view_tags.split(',').map((tag, idx) => (
                                        <span className="tag_item" key={idx}>{tag}</span>
                                    ))}
                                    </div>
                                : null}
                            </div>
                            {lang==="kr" ? 
                            <div className="view_footer">
                                <div className="navigator">
                                    {(view_previd) ? 
                                    <button className="btn_view_prev" onClick={goPrevView}><i className="btn_page_prev"></i><span>이전</span></button>
                                    : null }
                                    {(view_nextid) ? 
                                    <button className="btn_view_next" onClick={goNextView}><span>다음</span><i className="btn_page_next"></i></button>
                                    : null }
                                </div>
                                <div className="view_btn_wrap">
                                    <button className="btn btn_blue btn_sm" onClick={goList}>목록 보기</button>
                                    {admin ? 
                                    <AuthenticatedTemplate>
                                        <button className="btn btn_blue btn_sm ml30" onClick={editView}>수정</button>
                                        <button className="btn btn_blue btn_sm ml30" onClick={deleteView}>삭제</button>
                                    </AuthenticatedTemplate>
                                    : null }
                                </div>
                            </div>
                            : 
                            <div className="view_footer">
                                <div className="navigator">
                                    {(view_previd) ? 
                                    <button className="btn_view_prev" onClick={goPrevView}><i className="btn_page_prev"></i><span>Prev</span></button>
                                    : null }
                                    {(view_nextid) ? 
                                    <button className="btn_view_next" onClick={goNextView}><span>Next</span><i className="btn_page_next"></i></button>
                                    : null }
                                </div>
                                <div className="view_btn_wrap">
                                    <button className="btn btn_blue btn_sm" onClick={goList}>List</button>
                                    {admin ? 
                                    <AuthenticatedTemplate>
                                        <button className="btn btn_blue btn_sm ml30" onClick={editView}>Modify</button>
                                        <button className="btn btn_blue btn_sm ml30" onClick={deleteView}>Delete</button>
                                    </AuthenticatedTemplate>
                                    : null }
                                </div>
                            </div>
                            }
                        </div>
                    </section>
                    : null }
                </div>
            </div>
            
            <Footer lang={lang}></Footer>
        </>
    ); 
}; 