/* eslint-disable */ 
import React, { useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import $ from 'jquery';
import LottiePlayer from '../modules/LottiePlayer';
import { BrowserView, MobileView } from 'react-device-detect';

export default function Business(prop) {
    let lang = prop.lang;
    useEffect(() => {
        window.scrollTo(0, 0);
        
        $(window).on('scroll', (e)=>{
            let st = $(window).scrollTop();
            let wh = $(window).innerHeight();

            $('.js-fadeIn').each(function() {
                let this_top = $(this).offset().top;
                //console.log(st+wh,this_top);
                if (st+wh > this_top) {
                    $(this).addClass('on');
                }
            })
        })
        $(window).trigger('scroll');
        return () => {
            $(window).off('scroll');
        }

    }, []);
    return ( 
        <>
            <div id="container" >
                <div id="contents" className="business">
                    <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    <span>BUSINESS</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <section className="section full-section business_type1">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header">
                                        {lang ==="kr" ? 
                                        <p className="tit">
                                            <span>모픽의 솔루션은 여러분의 비즈니스에 필요한 상태로 제공됩니다</span></p>
                                        :
                                        <p className="tit"><span>MOPIC’s solution is provided to customers with it tailored to their needs</span></p>
                                        }
                                        <div className="lottiePlayer">
                                            <LottiePlayer url={"business_cube.json"} />
                                        </div>
                                        {/* <MobileView><LottiePlayer url={"business_cube.json"} /></MobileView> */}
                                        {lang ==="kr" ? 
                                        <p className='txt'>
                                            고객 시스템에 결합할 수 있는 소프트웨어와 ODM을 토탈 솔루션으로 공급하거나, 라이트필드 3D 설계 사양만을 제공할 수 있습니다. 3D 콘텐츠를 최적의 입체영상으로 추출해 보여줄 수 있는 API도 제공하고 있습니다. <br /><br />
                                            라이트필드 3D 솔루션 도입을 망설이는 고객을 위해, 손쉽게 테스트해 볼 수 있는 모듈 형식의 샘플도 제공하고 있습니다. 여러분의 비즈니스에 라이트필드 3D가 어떤 가치를 추가할 수 있을 지, 빠르고 경제적으로 검증해 볼 수 있습니다. 
                                        </p>
                                        :
                                        <p className='txt'>
                                            MOPIC can provide software and ODM that can be integrated into the customer’s system as well as providing specifications of Light Field 3D designs. MOPIC also offers APIs that enable the software to extract and portray 3D content as optimal stereoscopic images. <br /><br />
                                            For customers who are hesitant to adopt Light Field 3D solutions, MOPIC also provides samples in modular forms that are convenient to test. You can verify what value Light Field 3D can bring to your business in a prompt and economical way.
                                        </p>
                                        }
                                        
                                        <div className='mt30'>
                                            <Link to={"/"+lang+"/lightfield"} className='btn_arrow'>Key Technologies<i className="ic_arrow_link"></i></Link>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        
                                        {/* <BrowserView><LottiePlayer url={"business_cube.json"} /></BrowserView> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section business_type2 js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header">
                                        {lang ==="kr" ? 
                                        <p className="tit">
                                        라이트필드 3D가 필요하세요? <br />
                                        요구에 맞는 솔루션을 공급해 드립니다 
                                        </p>
                                        :
                                        <p className="tit">
                                        Do you need 3D Light Field? <br />
                                        MOPIC offers custom-made solutions
                                        </p>
                                        }
                                    </div>
                                    <div className="panel-body">
                                        <div className="img_wrap">
                                            {lang ==="kr" ? 
                                            <img src="/resources/images/sub/odm-image.png" alt="" />
                                            :
                                            <img src="/resources/images/sub/odm-image_en.png" alt="" />
                                            }
                                        </div>
                                        <div className="txt_wrap">
                                            <div>
                                                {lang ==="kr" ? 
                                                <p className="txt">
                                                모픽은 라이트필드 3D에 관한 모든 요소 기술을 보유하고 있습니다. 이 기술들을 조합하여 고품질의 제품을 빠르고 합리적으로 공급할 수 있습니다.<br />
                                                원하는 제품을 알려주세요! 기존 시스템에 완벽히 결합할 수 있는 라이트필드 3D 소프트웨어 솔루션과 디바이스 제작까지 On-Demand 토탈 솔루션을 제공합니다.
                                                </p>
                                                :
                                                <p className="txt">
                                                MOPIC has all elemental technologies of the 3DLight Field. By combining these technologies, MOPIC can provide high-quality products to customers in a prompt and reasonable manner. <br />
                                                MOPIC can offer Light Field 3D software solutions and devices that can be wholly integrated into existing systems.
                                                </p>
                                                }
                                                <div>
                                                    <Link to={"/"+lang+"/lightfield"} className='btn_arrow'>Key Technologies<i className="ic_arrow_link"></i></Link>
                                                </div>
                                                <div>
                                                    {lang ==="kr" ? 
                                                    <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>비즈니스 문의<i className="ic_arrow_link"></i></Link>
                                                    :
                                                    <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>Business Inquiries<i className="ic_arrow_link"></i></Link>
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section business_type2 js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                            <div className="content-panel">
                                <div className="panel-header">
                                    {lang ==="kr" ? 
                                    <p className="tit">
                                    여러분의 라인업에 라이트필드 3D를 <br className='pc_only'/>추가하고 싶다면? 
                                    </p>
                                    :
                                    <p className="tit">
                                        If you intend to add Light Field 3D to their lineup,<br className='pc_only' /> we will tell you how
                                    </p>
                                    }
                                </div>
                                <div className="panel-body">
                                    <div className="img_wrap">
                                        {lang ==="kr" ? 
                                        <img src="/resources/images/sub/license-image.png" alt="" />
                                        :
                                        <img src="/resources/images/sub/license-image_en.png" alt="" />
                                        }
                                    </div>
                                    <div className="txt_wrap">
                                        <div>
                                            {lang ==="kr" ? 
                                            <p className="txt">
                                            고객사의 라인업에 최신 기술이 적용된 라이트필드 3D 디스플레이를 추가할 수 있습니다. <br />
                                            모픽은 고객사 시스템에 완벽히 결합하는 라이트필드 3D 소프트웨어와 라이트필드 3D 광학 설계 노하우를 제공합니다. 광학 설계와 함께 제공되는 Auto Calibration으로 렌즈 부착 오차를 혁신적으로 늘려 양산성을 확보할 수 있습니다.
                                            </p>
                                            :
                                            <p className="txt">
                                                Light Field 3d displays with the latest technology can be added to customer’s lineup. <br />
                                                MOPIC provides Light Field 3D software and Light Field 3D optical design know-how that are smoothly integrated into customer systems. Auto Calibration, which comes with optical designs, significantly increases error tolerances for lens attachment to ensure mass production.
                                            </p>
                                            }
                                            
                                            <div>
                                                <Link to={"/"+lang+"/lightfield"} className='btn_arrow'>Key Technologies<i className="ic_arrow_link"></i></Link>
                                            </div>
                                            <div>
                                                {lang ==="kr" ? 
                                                <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>비즈니스 문의<i className="ic_arrow_link"></i></Link>
                                                :
                                                <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>Business Inquiries<i className="ic_arrow_link"></i></Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section business_type3 js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                            <div className="content-panel">
                                <div className="panel-header">
                                    {lang ==="kr" ? 
                                    <p className="tit">
                                    준비된 샘플을 여러분의 시스템에 <br className='pc_only'/>직접 연결해 그 가치를 확인해 보세요! 
                                    </p>
                                    :
                                    <p className="tit">
                                    Connect the prepared samples directly <br className='pc_only' /> to your system and then see how valuable they are
                                    </p>
                                    }
                                </div>
                                <div className="panel-body">
                                    {lang ==="kr" ? 
                                    <ul className="process_list">
                                        <li className="pl_item">
                                            <span>고객 요청사항</span>
                                            <i className="ic_dot0"></i>
                                        </li>
                                        <li className="pl_item">
                                            <span>샘플 판매</span>
                                            <i className="ic_dot1"></i>
                                        </li>
                                        <li className="pl_item">
                                            <span>적용 후 검토</span>
                                            <i className="ic_dot2"></i>
                                        </li>
                                        <li className="pl_item">
                                            <span>상세 협의</span>
                                            <i className="ic_dot3"></i>
                                        </li>
                                        <li className="pl_item">
                                            <span>솔루션 공급</span>
                                            <i className="ic_dot4"></i>
                                        </li>
                                    </ul>
                                    :
                                    <ul className="process_list">
                                        <li className="pl_item">
                                            <span>Customer Requests</span>
                                            <i className="ic_dot0"></i>
                                        </li>
                                        <li className="pl_item">
                                            <span>Sample Sales</span>
                                            <i className="ic_dot1"></i>
                                        </li>
                                        <li className="pl_item">
                                            <span>Review after applying samples</span>
                                            <i className="ic_dot2"></i>
                                        </li>
                                        <li className="pl_item">
                                            <span>Detailed<br className='pc_only'/> Consultation</span>
                                            <i className="ic_dot3"></i>
                                        </li>
                                        <li className="pl_item">
                                            <span>Solution Supply</span>
                                            <i className="ic_dot4"></i>
                                        </li>
                                    </ul>
                                    }
                                    {lang ==="kr" ? 
                                    <p className="txt">
                                    새로운 비즈니스의 가능성을 검증해 보기 위해 처음부터 개발할 필요가 없습니다. <br />
                                    모픽은 A부터 Z까지 각각의 솔루션을 모듈식으로 제공하여, 고객사는 자사의 시스템에 모픽 샘플을 즉시 연결해볼 수 있습니다. <br />
                                    이것으로 라이트필드 3D의 효과를 미리 확인하고 결정할 수 있습니다. 
                                    </p>
                                    :
                                    <p className="txt">
                                    You don't need to develop the samples from scratch to verify the potential of new businesses. <br />
                                    MOPIC provides a range of modular solutions, allowing you to instantly connect MOPIC'S samples to your systems. <br />
                                    These samples enable you to check and determine the effectiveness of Light Field 3D in advance. 
                                    </p>
                                    }

                                    
                                    
                                    <div className="btn_wrap2">
                                        <span>
                                            <Link to={"/"+lang+"/lightfield"} className='btn_arrow'>Key Technologies<i className="ic_arrow_link"></i></Link>
                                        </span>
                                        <span>
                                        {lang ==="kr" ? 
                                        <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>비즈니스 문의<i className="ic_arrow_link"></i></Link>
                                        :
                                        <Link to={"/"+lang+"/contact"} target="_blank" rel="noopener noreferrer" className='btn_arrow'>Business Inquiries<i className="ic_arrow_link"></i></Link>
                                        }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section business_banner js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                                {lang ==="kr" ? 
                                <div className="cont_box">
                                    <p className="tit">
                                        3D 창작자 환영! <br />여러분의 콘텐츠를 보다 사실적으로 전달하세요 
                                    </p>
                                    <p className="txt">
                                        MOPIC Hub는 여러분의 3차원 그래픽에 사실적인 입체감을 추가할 수 있습니다. <br />
                                        지금, 파트너십을 문의하세요! 최적의 실재감을 표현하는 입체영상 노하우를 아낌없이 나눠 드리겠습니다. <br />
                                        현재, 3D 콘텐츠 제작사에게 무료 라이선스를 제공하고 있습니다. 
                                    </p>
                                    <div className="btn_wrap">
                                        <Link to={"/"+lang+"/contact/"} target="_blank" rel="noopener noreferrer" className='btn btn_sm btn_white_line'>파트너쉽 문의</Link>
                                    </div>
                                </div>
                                :
                                <div className="cont_box">
                                    <p className="tit">
                                        Welcome 3D creators! <br />Apply your content realistically
                                    </p>
                                    <p className="txt">
                                        MOPIC Hub allows you to add three dimensional effects to your 3D graphics. <br />
                                        Now, it is time to ask for a partnership! MOPIC will unsparingly share the know-how of stereoscopic images that express the optimal sense of reality. <br />
                                        MOPIC are currently providing complimentary licenses to 3D content producers.
                                    </p>
                                    <div className="btn_wrap">
                                        <Link to={"/"+lang+"/contact/"} target="_blank" rel="noopener noreferrer" className='btn btn_sm btn_white_line'>Partnership Inquiries</Link>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </section>
                    <section className="section full-section business_type4 js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                            <div className="content-panel">
                                <div className="panel-body">
                                    <div className="pricing_cols">
                                        <div className="pr_col">
                                            <p className="tit">MOPIC <br className='pc_only'/>Pricing</p>
                                        </div>
                                        {lang ==="kr" ? 
                                        <div className="pr_col">
                                            <div className="pr_box type0">
                                                <p className="pr_box_tit"><span>SI & ODM</span></p>
                                                <p className="pr_box_sub_tit">
                                                    라이트필드 3D 커스텀 제작이 필요하세요?
                                                </p>
                                                <p className="pr_box_txt">
                                                    고객사의 시스템에 완벽히 결합하는 라이트필드 3D 소프트웨어, 그리고 고객사의 요청에 맞는 입체영상 디바이스 제작까지 On-demand 토탈 솔루션을 제공합니다.
                                                </p>
                                                <div className="btn_wrap">
                                                    <Link to={"/"+lang+"/contact"} className="btn btn_md btn_round">솔루션 문의하기</Link>
                                                </div>
                                            </div>
                                            
                                            <div className="mb_only">
                                                <ul className='grade_options type0'>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">3D Camera</p>
                                                        <p className="option_txt">고객 필요에 따라 제공</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Hub</p>
                                                        <p className="option_txt">API 제공</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Lib</p>
                                                        <p className="option_txt">Library 또는 APP 제공</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Device</p>
                                                        <p className="option_txt">ODM 제공</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        : 
                                        <div className="pr_col">
                                            <div className="pr_box type0">
                                                <p className="pr_box_tit"><span>SI & ODM</span></p>
                                                <p className="pr_box_sub_tit">
                                                Do you need a customized production of 3D Light Field?
                                                </p>
                                                <p className="pr_box_txt">
                                                MOPIC provides Light Field 3D software that can be fully integrated into the customer’s system as well as on-demand total solutions encompassing the production of stereoscopic image devices tailored to r the customer’s request.
                                                </p>
                                                <div className="btn_wrap">
                                                    <Link to={"/"+lang+"/contact"} className="btn btn_md btn_round">Ask about the solution</Link>
                                                </div>
                                            </div>
                                            
                                            <div className="mb_only">
                                                <ul className='grade_options type0'>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">3D Camera</p>
                                                        <p className="option_txt">Provide a 3D camera if customers need it</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Hub</p>
                                                        <p className="option_txt">Provide API</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Lib</p>
                                                        <p className="option_txt">Provide Library or APP</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Device</p>
                                                        <p className="option_txt">Provide ODM</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                        {lang ==="kr" ? 
                                        <div className="pr_col">
                                            <div className="pr_box type1">
                                                <p className="pr_box_tit"><span>License</span></p>
                                                <p className="pr_box_sub_tit">
                                                    직접 라이트필드 3D 디바이스를 제작하고 싶나요?
                                                </p>
                                                <p className="pr_box_txt">
                                                고객사의 시스템에 완벽히 결합하는 라이트필드 3D 소프트웨어와 라이트필드 3D를 제작할 수 있는 광학 설계 노하우를 제공합니다.
                                                </p>
                                                <div className="btn_wrap">
                                                    <Link to={"/"+lang+"/contact"} className="btn btn_md btn_round">라이선스 문의하기</Link>
                                                </div>
                                            </div>
                                            
                                            <div className="mb_only">
                                                <ul className='grade_options type1'>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Hub</p>
                                                        <p className="option_txt">API 제공</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Lib</p>
                                                        <p className="option_txt">Library 또는 APP 제공</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Device</p>
                                                        <p className="option_txt">ODM 제공</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        <div className="pr_col">
                                            <div className="pr_box type1">
                                                <p className="pr_box_tit"><span>License</span></p>
                                                <p className="pr_box_sub_tit">
                                                Do you want to produce Light Field 3D devices on your own?
                                                </p>
                                                <p className="pr_box_txt">
                                                MOPIC offers Light Field 3D software that can be fully integrated into the customer’s system. In turn, it provides hardware specifications and technical know-how to ensure that  customers can produce stereoscopic image devices.
                                                </p>
                                                <div className="btn_wrap">
                                                    <Link to={"/"+lang+"/contact"} className="btn btn_md btn_round">Ask about the license</Link>
                                                </div>
                                            </div>
                                            
                                            <div className="mb_only">
                                                <ul className='grade_options type1'>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Hub</p>
                                                        <p className="option_txt">Provide API</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Lib</p>
                                                        <p className="option_txt">Provide Library or APP</p>
                                                    </li>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Device</p>
                                                        <p className="option_txt">Only provide standards of designs and assessment</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                        {lang ==="kr" ? 
                                        <div className="pr_col">
                                            <div className="pr_box type2">
                                                <p className="pr_box_tit"><span>Complimentary License <br />(Content Creator Only)</span></p>
                                                <p className="pr_box_sub_tit">
                                                3D 콘텐츠를 가지고 있나요? <br /> 더 몰입감 있게 보여주세요!
                                                </p>
                                                <p className="pr_box_txt">
                                                3D 콘텐츠에서 최적의 입체감을 추출해 라이트필드 3D 형식으로 변환하는 API를 제공합니다. 콘텐츠 제작사에게는 파트너십을 통해 무료 라이선스를 제공합니다.
                                                </p>
                                                <div className="btn_wrap">
                                                    <Link to={"/"+lang+"/contact"} className="btn btn_md btn_round">파트너십 문의하기</Link>
                                                </div>
                                            </div>
                                            
                                            <div className="mb_only">
                                                <ul className='grade_options type2'>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Hub</p>
                                                        <p className="option_txt">API 제공</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        <div className="pr_col">
                                            <div className="pr_box type2">
                                                <p className="pr_box_tit"><span>Complimentary License <br />(Content Creator Only)</span></p>
                                                <p className="pr_box_sub_tit">
                                                Do you have 3D content? <br /> Show it more realistically!
                                                </p>
                                                <p className="pr_box_txt">
                                                MOPIC provides APIs that extract optimal three-dimensional effects from 3D content and then converts it into 3D formats. MOPIC offers complimentary licenses to content creators through the form of partnership.
                                                </p>
                                                <div className="btn_wrap">
                                                    <Link to={"/"+lang+"/contact"} className="btn btn_md btn_round">Ask about the partnership</Link>
                                                </div>
                                            </div>
                                            
                                            <div className="mb_only">
                                                <ul className='grade_options type2'>
                                                    <li>
                                                        <i></i>
                                                        <p className="option_tit">MOPIC Hub</p>
                                                        <p className="option_txt">Provide API</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className="pricing_cols pricing_info">
                                        <div className="pr_col">
                                            <p className="list_tit">3D Camera</p>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check checked type0">
                                                <i></i>
                                                {lang ==="kr" ? 
                                                <p className='txt'>고객 필요에 따라 제공</p>
                                                :
                                                <p className='txt'>Provide a 3D camera if customers need it</p>
                                                }
                                            </div>
                                            
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check">
                                                <i></i>
                                            </div>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check">
                                                <i></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pricing_cols pricing_info">
                                        <div className="pr_col">
                                            <p className="list_tit">MOPIC Hub</p>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check checked type0">
                                                <i></i>
                                                {lang ==="kr" ? 
                                                <p className='txt'>API 제공</p>
                                                :
                                                <p className='txt'>Provide API</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check checked type1">
                                                <i></i>
                                                {lang ==="kr" ? 
                                                <p className='txt'>API 제공</p>
                                                :
                                                <p className='txt'>Provide API</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check checked type2">
                                                <i></i>
                                                {lang ==="kr" ? 
                                                <p className='txt'>API 제공</p>
                                                :
                                                <p className='txt'>Provide API</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pricing_cols pricing_info">
                                        <div className="pr_col">
                                            <p className="list_tit">MOPIC Lib</p>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check checked type0">
                                                <i></i>
                                                {lang ==="kr" ? 
                                                <p className='txt'>Library 또는 APP 제공</p>
                                                :
                                                <p className='txt'>Provide Library or APP</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check checked type1">
                                                <i></i>
                                                {lang ==="kr" ? 
                                                <p className='txt'>Library 또는 APP 제공</p>
                                                :
                                                <p className='txt'>Provide Library or APP</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check">
                                                <i></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pricing_cols pricing_info">
                                        <div className="pr_col">
                                            <p className="list_tit">MOPIC Device</p>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check checked type0">
                                                <i></i>
                                                {lang ==="kr" ? 
                                                <p className='txt'>ODM 제공</p>
                                                :
                                                <p className='txt'>Provide ODM</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check checked type1">
                                                <i></i>
                                                {lang ==="kr" ? 
                                                <p className='txt'>설계 및 평가 기준만 제공</p>
                                                :
                                                <p className='txt'>Only provide standards of designs and assessment</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="pr_col">
                                            <div className="list_check">
                                                <i></i>
                                            </div>
                                        </div>
                                    </div>
                                    {lang ==="kr" ? 
                                    <p className='pr_desc'>* 고객 요청사항에 맞게 키 컴포넌트를 조합하여 솔루션 단위로 공급합니다. 컴포넌트 개별 판매는 하지 않습니다. </p>
                                    :
                                    <p className='pr_desc'>* MOPIC combines key components customized for the customer’s needs and then supplies them on a solution-by-solution basis. MOPIC does not sell components individually.</p>
                                    }
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                    


                </div>
            </div>
            <Footer lang={lang}></Footer>
        </>
    ); 
}; 