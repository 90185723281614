import React, { useEffect } from "react";
import { SelectPicker } from "rsuite";
import { Link, useNavigate } from "react-router-dom";

export default function TechList(prop) {
  let lang = prop.lang ? prop.lang : "kr";
  const navigate = useNavigate();
  useEffect(() => {}, []);
  const data = ["EN", "KO"].map((item) => ({ label: item, value: item }));
  return (
    <>
      <div className="tech_header">
        <div className="header_inner">
          <Link to={"/" + lang + "/tech/list"}>
            <img src="/resources/images/common/logo_bk.png" alt="" />
          </Link>
          <div className="abr">
            <SelectPicker
              data={data}
              searchable={false}
              cleanable={false}
              defaultValue={lang.toUpperCase()}
              style={{ width: 100 }}
              onChange={(value) => {
                navigate("/" + value.toLowerCase() + "/tech/list");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
