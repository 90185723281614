/* eslint-disable */ 
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Footer from '../common/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'
import axios from 'axios';
import $ from 'jquery';
//import ImagePicker from 'antd-mobile/lib/image-picker';
import imageCompression from "browser-image-compression";
import JoditEditor from 'jodit-react';
import APIURI from './APIURI';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

export default function ContentsWrite(prop) {

    let _imageFile;
    let data= {
        category : ''
    };
    const [slide, setSlide] = useState(false);
    const [opened, setOpened] = useState(false);
    const [fileImg, setFileImg] = useState('');
    const [title, setTitle] = useState('');
    const [tagAry, setTagAry] = useState('');
    const [category, setCategory] = useState('언론보도');
    const [loaded, setLoaded] = useState(false);

    
    const editor = useRef(null);
	const [content, setContent] = useState('');
    const placeholder = "placeholder"
    let isSupport = false;
    // const config = useMemo(
	// 	{
	// 		readonly: false, // all options from https://xdsoft.net/jodit/doc/,
	// 		placeholder: placeholder || 'Start typings...'
	// 	},
	// 	[placeholder]
	// );

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: "내용을 입력해주세요",
        height:600,
        
    }

    // let Editor = toastui.Editor;
    // let editor = null;

    let type=prop.type.split('_')[0];
    let lang=prop.lang;
    let admin = prop.type.indexOf("ADMIN") > -1;

    let params = useParams();
    let sid = params.id ? params.id : '' ;

    let navigate = useNavigate();
    if (type.toLowerCase() == "support") {
        isSupport = true;
    }

    
    useEffect(() => {
        window.scrollTo(0, 0);
        
        if (sid) {
            getData();
        }
                
        return () => {
            
        }
    }, []);

    const downloadImage = async (image, id) => {
        const res = await axios.get(APIURI+'image/?id='+ id);
        console.log(image, res.data.image);
        image.src=res.data.image;
    }
    /*
    useEffect(() => {
        if (loaded) return;
        const cont_box = document.getElementsByClassName('jodit-wysiwyg')[0];
        console.log("!!!!!");
        if (cont_box ) {
            console.log("@@@@@");
            const images = cont_box.querySelectorAll('img');
            for (const img of images) {
                console.log(img.src);
                if (img.src.startsWith('blob:')) {
                    let id = img.src.replace('blob:', '');
                    img.id="t"
                    downloadImage(img, id);
                }
            }
            setLoaded(true);
        }
            
    }, [content]);*/
    
    const onSubmit = async () => {
        if (type.toLowerCase() ) {
            if (fileImg) {
                sendData();
            }
            else {
                // let a= editor.getHTML();
                //data.fileImg = a.substr(a.indexOf('data:image'), a.length).split('"')[0];
                //sendData();
                alert('대표이미지가 없습니다.')
            }
        }
    };

    const [imgBase64, setImgBase64] = useState([]); // 파일 base64


    const actionImgCompress = async fileSrc => {
        //console.log("압축 시작");
      
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 700,
          useWebWorker: true
        };
        try {
          const compressedFile = await imageCompression(fileSrc, options);
      
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onloadend = () => {
            //console.log('reader.result' , reader.result);
            setFileImg(reader.result);
            $('.img_wrap').empty();
            $('.img_wrap').append('<img src="'+ reader.result+'" alt="">')
            //sendData();
          };
        } catch (error) {
          console.log(error);
        }
    };



    const handleChangeFile = (e) => {
        const files = e.target.files;
        //data.fileImg = [];
        
        for(var i=0; i < files.length; i++) {
            if (files[i]) {
                let reader = new FileReader();
                reader.readAsDataURL(files[i]);
                _imageFile = files[i];
                actionImgCompress(_imageFile);
                // reader.onloadend = () => {
                //     const base64 = reader.result;
                //     if (base64) {
                //         let base64Sub = base64.toString();
                //         data.fileImg = [...data.fileImg, base64Sub].toString()
                //         $('.img_wrap').empty();
                //         $('.img_wrap').append('<img src="'+ data.fileImg+'" alt="">')
                //     }
                // }
            }
        }
    }
    async function parseImage(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const images = doc.querySelectorAll('img');
        
        for (const img of images) {
            if (img.src.startsWith('blob:')) {
                let id = img.src.replace('blob:', '');
                const res = await axios.get(APIURI+'image/?id='+ id);
                img.src=res.data.image;
            }
        }
        setContent(doc.getElementsByTagName('body')[0].innerHTML);
        return doc.innerHTML;
    }

    function getData() {
        let _url = APIURI+type.toLowerCase()+'/'+lang.toLowerCase()+'/1/'
        axios.get(_url, {
            params: {
                pid : sid
            }
        })
        .then(function (response) {
            let viewdata = response.data[0];
            //console.log(viewdata)
            if (viewdata.post_title) {
                $('#title').val(viewdata.post_title.replaceAll("&#39;","'"));
                setTitle(viewdata.post_title.replaceAll("&#39;","'"));
            }
            if (viewdata.post_contents) {
                const p_cont = parseImage(viewdata.post_contents);
                //editor.setHTML(viewdata.post_contents)
                
            }
            if (viewdata.post_image) {
                $('.img_wrap').empty();
                $('.img_wrap').append('<img src="'+ viewdata.post_image+'" alt="">')
                //data.fileImg=viewdata.post_image;
                setFileImg(viewdata.post_image)
            }
            if (viewdata.is_opened_post) {
                setOpened( viewdata.is_opened_post);
                $('#chk_opened').prop('checked', viewdata.is_opened_post);
                //data.opened = viewdata.is_opened_post;
            }
            if (viewdata.is_slide_post) {
                $('#chk_slide').prop('checked', viewdata.is_slide_post);
                //data.slide = viewdata.is_slide_post;
                setSlide(viewdata.is_slide_post)
            }
            if (viewdata.post_category) {
                //console.log(viewdata.post_category)
                setCategory(viewdata.post_category);
            }
            if (viewdata.post_tags) {
                $('#tags').val(viewdata.post_tags);
                setTagAry(viewdata.post_tags);
            }

        })
    }
       
    function sendData() {
         //console.log('sendData', sid );
        // console.log(editor.getHTML());
        // console.log(editor.getHTML().replaceAll("'","&#39;"));
        if (sid) {
            let _data = {
                post_id : sid,
                post_title : title.replaceAll("'","&#39;"), 
                post_category: category,
                post_contents : content.replaceAll("'","&#39;"), //editor.getHTML().replaceAll("'","&#39;"),
                post_image : fileImg,
                post_tags: tagAry,
                is_slide_post : slide, 
                is_opened_post : opened 
            }
            
            axios({
                method: 'put',
                url: APIURI+type.toLowerCase()+'/'+lang.toLowerCase(),
                data : _data
            })
            .then(function (response) {
                //console.log(response.data);
                if (response.data.result === "FAIL") {

                }
                else {
                    navigate('/'+lang.toLowerCase()+'/'+prop.type.toLowerCase() + '/list');
                }
            });

        }
        else {
            //let content_data = content.replaceAll("'","&#39;");
            //console.log(content_data);

            

            (async () => {
                try {
                    let content_data = await extractBlobDataFromString(content);
                    console.log('content_data' , content_data);
                    let _data = {
                        post_title : title.replaceAll("'","&#39;"),
                        post_category: category,
                        post_contents : content_data, //editor.getHTML().replaceAll("'","&#39;"),
                        post_image : fileImg,
                        post_tags: tagAry,
                        is_slide_post : slide, 
                        is_opened_post : opened
                    }
                    
                    axios({
                        method: 'post',
                        url: APIURI+type.toLowerCase()+'/'+lang.toLowerCase(),
                        data : _data
                    })
                    .then(function (response) {
                        if (response.data.result === "FAIL") {
                        }
                        else {
                            navigate('/'+lang.toLowerCase()+'/'+prop.type.toLowerCase() + '/list');
                        }
                    });

                } catch (error) {
                    console.error('Error in processImages:', error);
                }

            //let content_data = await extractBlobDataFromString(content);
            //const { blobData, updatedHtmlString } = result;

            // Blob 데이터와 업데이트된 HTML 문자열을 확인합니다.
            //console.log('Blob Data:', blobData);
            //console.log('Updated HTML String:', updatedHtmlString);
            //console.log(content_data);
            //return;

                
            
            })();
        }
    }
    async function sendImage(src) {
        let uuid;
        await axios({
            method: 'post',
            url: APIURI+'image',
            data : {image:src}
        })
        .then(function (response) {
            if (response.data.result === "FAIL") {
                
            }
            else {
                // UUID 값을 src 에 설정합니다.
                //img.src = `blob:${response.data.id}`;
                uuid = response.data.id
                console.log(response.data);
            }
        });
        return uuid;
        //console.log('sendImage');
    }
    async function extractBlobDataFromString(htmlString) {

        /*const _images = document.getElementsByClassName('jodit-wysiwyg')[0].querySelectorAll('img');
        _images.forEach((img) => {
            console.log(img.width);
        });


        return ;*/
        // HTML Tag를 DOM에 복제합니다.
        document.getElementById('temp_content_wrap').innerHTML = htmlString;
        
        // 이미지 태그를 선택합니다.
        const images = document.getElementById('temp_content_wrap').querySelectorAll('img');

        // 이미지 태그의 src 속성을 순회하며 Blob 데이터를 추출합니다.
        for (const img of images) {
            let src = img.src;
            if (src.startsWith('data:image')) {
                // 이미지의 사이즈를 조정합니다.
                    try {
                        const im = await processImage(src);
                        // console.log('Width:',img.width, 'Height:',img.height, 'naturalWidth:', im.naturalWidth, 'naturalHeight:', im.naturalHeight);

                        if (im.naturalWidth > img.width && im.naturalWidth > 0){
                            // canvas 생성
                            var canvas = document.createElement("canvas")
                            canvas.width= img.width;
                            canvas.height= img.height;
                            var ctx = canvas.getContext("2d");
                            //Resize your image here
                            ctx.drawImage(img, 0, 0, img.width, img.height);
                            // To blob
                            var jpgBlob = await new Promise(resolve => canvas.toBlob(resolve,'image/jpg', 1)); // refer to canvas.toblob API
                            
                            // FileReader를 사용하여 Blob을 Data URL로 변환
                            const base64data = await readFileAsDataURL(jpgBlob);
                            src = base64data;
                        }
                        // 이미지를 서버에 전송 후 UUID 값을 반환받습니다.
                        let uuid2 = await sendImage(src);
                        img.src =`blob:${uuid2}`;

                    } catch (error) {
                        console.error('Error processing image:', error);
                    }

                    
            }
        };

        // 변환된 HTML을 문자열로 반환합니다.
        const updatedHtmlString = document.getElementById('temp_content_wrap').innerHTML;

        let content_data = updatedHtmlString.replaceAll("'","&#39;");
        console.log(content_data);
        return content_data;
    }
    async function processImage(src) {
        try {
            const img = await loadImage(src);
            return img;
        } catch (error) {
            console.error('Image failed to load:', error);
        }
    }
    function loadImage(src) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            if (img.complete) {
                img.onload();
            }
            img.onerror = reject;
            img.src = src;
        });
    }

    // FileReader를 Promise로 래핑하는 함수
    function readFileAsDataURL(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }


    const options = (lang==="kr") ? [
        { value: '언론보도', label: '언론보도' },
        { value: '인터뷰', label: '인터뷰' }
    ] : [
        { value: 'Press', label: 'Press' },
        { value: 'Interview', label: 'Interview' }
    ];
    const options_support = [
        { value: 'Manual', label: 'Manual' },
        { value: 'Notice', label: 'Notice' },
        { value: 'Download', label: 'Download' },
        { value: 'FAQ', label: 'FAQ' }
    ];

    return ( 
        admin ? 
        <>
        <AuthenticatedTemplate>
            <div id="container" >
                <div id="contents" className={ type.toLowerCase() + " write_mode"}>
                    <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    { type==="NEWS" || type==="NEWS_ADMIN" ?
                                        <span>NEWS</span>
                                        : null
                                    }
                                    { type==="CASESTUDY" || type==="CASESTUDY_ADMIN" ?
                                        <span>CASE STUDY</span>
                                        : null
                                    }
                                    { type==="SUPPORT" || type==="SUPPORT_ADMIN" ?
                                        <span>SUPPORT</span>
                                        : null
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <section className="section basic-section view_container">
                        <div className="section-inner">
                            <div className="view_header">
                                { type==="NEWS" ?
                                <div className="category">
                                    <span>카테고리</span>
                                    <Select defaultValue={category} options={options} isSearchable={false} placeholder="카테고리를 선택해주세요." onChange={(e)=> {
                                        setCategory(e.value);
                                    }}/>
                                </div>
                                : null
                                }
                                { type==="SUPPORT" ?
                                <div className="category">
                                    <span>카테고리</span>
                                    <Select defaultValue={category} options={options_support} isSearchable={false} placeholder="카테고리를 선택해주세요." onChange={(e)=> {
                                        setCategory(e.value);
                                    }}/>
                                </div>
                                : null
                                }
                                <div className="title">
                                    <span>제목</span>
                                    <input type="text" id="title" onChange={(e)=> {
                                        setTitle(e.currentTarget.value);
                                    }}/>
                                </div>
                                <div className="setting">

                                    <div className="sel_box trp checkbox-box">
                                        <input type="checkbox" name="chk_opened" id="chk_opened" onChange={(e)=> {
                                            //data.opened = e.target.checked;
                                            setOpened(e.target.checked)
                                        }}/>
                                        <i></i>
                                        <label htmlFor="chk_opened">게시글을 공개합니다.</label>
                                    </div>
                                    { type==="SUPPORT" ? null :
                                    <div className="sel_box trp checkbox-box">
                                        <input type="checkbox" name="chk_slide" id="chk_slide" onChange={(e)=> {
                                            //data.slide= e.target.checked;
                                            setSlide(e.target.checked)
                                            //console.log('data.slide', data.slide);
                                        }}/>
                                        <i></i>
                                        <label htmlFor="chk_slide">슬라이드에 추가합니다.</label>
                                    </div>
                                    }


                                </div>
                            </div>
                            <div className="view_body">
                                <div className="view_inner">
                                    {/* <div id="editor">

                                    </div> */}
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        config={config}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                        onChange={(newContent) => {}}
                                    />
                                </div>
                                
                                { type==="CASESTUDY" ?
                                <div className="tag_insert">
                                    
                                    <span>태그</span>
                                    <input type="text" id="tags" className='js-tag' onChange={(e)=> {
                                        setTagAry(e.currentTarget.value);
                                    }}/>
                                </div>
                                : null 
                                }
                                { type==="SUPPORT" ? null :
                                <div className="rep_image">
                                    <span>대표 이미지</span>
                                    <label htmlFor="reg_img" className="inp_file" accept="image/*">파일 선택</label>
                                    <input type="file" name="" id="reg_img" onChange={handleChangeFile}/>
                                    
                                    <div className="img_wrap">

                                    </div>
                                    <em className='js-fileName'></em>
                                </div>
                                }
                            </div>
                            <div className="view_footer">
                                <div className="view_btn_wrap">
                                    <button className="btn btn_blue btn_sm" onClick={(e)=> {
                                        
                                        if (isSupport) {
                                            sendData();
                                        }
                                        else {
                                            onSubmit();
                                        }
                                        //e.preventDefault();
                                        //sendData();
                                        //return false;
                                    }}>저장하기</button>

                                     {/* <button className="btn btn_blue btn_sm" onClick={(e)=> {
                                        // console.log(editor);
                                        // let temp =  editor.getHTML();
                                        // console.log(temp);
                                        // $('.temp_wrap').empty();
                                        // $('.temp_wrap').append(temp);
                                        console.log(content);
                                        
                                        //console.log(category)
                                        //onSubmit();
                                    }}>테스트 버튼</button>  */}
                                </div>
                            </div>
                            <div className="temp_wrap" style={{display:"none"}}></div>
                        </div>
                    </section>
                    <div id="temp_content_wrap"></div>
                    
                   

                </div>
            </div>
            
            <Footer lang={lang}></Footer>
            </AuthenticatedTemplate>
        </>
        :null 
    ); 
}; 