/* eslint-disable */ 
import React, { useEffect, useState } from 'react'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import axios from 'axios';
import APIURI from '../modules/APIURI';

export default function MainNewsSlide(prop) {
    
    let type= prop.type;
    let lang= prop.lang;
    useEffect(() => {
        searchList()
        return () => {
            
        }
    }, []);
    

    function searchList() {
        let _url =APIURI+type.toLowerCase()+'/'+lang.toLowerCase()+'/0/opened';
        //console.log(_url)
        axios.get(_url, {
            params: {
                pidx: 0,
                pcnt: 5
            }
        })
        .then(function (response) {
            //console.log(response.data);
                setSlideList(response.data); 
        })


    }
    
    function toDigit(num) {
        return (num<10) ? "0"+num: num;
    }
    function decodeDate(regist_date) {
        var _date = new Date(regist_date);
        return _date.getFullYear() + "-" + toDigit(_date.getMonth()+1) + "-"+ toDigit(_date.getDate());
    }
    let [slideList, setSlideList] = useState ([])

    function b64toBlob(b64Data, sliceSize=512) {
        const byteCharacters = atob(b64Data.replace(/^data:image\/[a-z]+;base64,/, ''));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/png' });
        return blob;
    }
    const downloadImage = async (container, id) => {
        const res = await axios.get(APIURI+'image/?id='+ id);
        const url = window.URL.createObjectURL(b64toBlob(res.data.image));
        //$("."+container).attr('src', res.data.image);
        $("."+container).attr('src', url);
    }

    return (
        <Swiper
            modules={[Navigation]}
            spaceBetween={30}
            slidesPerView={"auto"}
            navigation
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
            {slideList.map((row, idx) => (
                <SwiperSlide  key={"slidenews" +idx} className="news_item">
                    <Link to={ "/"+lang.toLowerCase()+"/"+type.toLowerCase()+"/view/"+row.post_id}>
                        <div className="img_wrap">
                            <div className="img_inner">
                                {/* <img src={row.post_image} alt="" /> */}
                                <img className={"img"+row.post_image_filename} loading={downloadImage("img"+row.post_image_filename,row.post_image_filename)}  alt="" />
                            </div>
                        </div>
                        <div className="cont_wrap">
                            <div className="cont_inner">
                                <p className="tit">{row.post_title.replaceAll("&#39;","'")}</p>
                                <p className="date">{decodeDate(row.regist_date)}</p>
                            </div>
                        </div>
                    </Link>


                </SwiperSlide>
            ))}

                    
        </Swiper>
    )
}