/* eslint-disable */ 

import React, { useState, useEffect } from 'react';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./config";

const msalInstance = new PublicClientApplication(msalConfig);

export const MsalProviderToPCA = (props) => {
    return (
        <MsalProvider instance={msalInstance}>
            {props.children}
        </MsalProvider>
    );
}

export const GetAccessToken = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    useEffect(() => {
    }, [accessToken])

    instance.acquireTokenSilent(request)
        .then((response) => {
            setAccessToken(response.accessToken);
        })
        .catch((e) => {
            instance.acquireTokenPopup(request)
                .then((response) => {
                    setAccessToken(response.accessToken);
                });
        });

}