// eslint-disable
import React, { useEffect, useState }  from 'react'; 
import Footer from '../common/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import $ from 'jquery';
//import LottiePlayer from '../modules/LottiePlayer';


export default function LightField(prop) {
    const [swiper, setSwiper] = useState(null);
    const lang = prop.lang;
    useEffect(()=>{
        window.scrollTo(0, 0);
        $('.sol_acd .sol_item .item_head button').off('click').on('click', function() {
            let idx = $('.sol_acd .sol_item .item_head button').index($(this));
            for (let i=0; i<$('.sol_acd .sol_item .item_head button').length; i++) {
                if (idx === i) {
                    //$('.sol_acd .sol_item').eq(i).removeClass('on');
                    //$('.sol_acd .sol_item').eq(i).find('.item_body').stop().slideUp(300);
                    $('#tech_v'+i)[0].currentTime=0;
                    $('#tech_v'+i)[0].play();
                }
                else {
                    $('.sol_acd .sol_item').eq(i).removeClass('on');
                    $('.sol_acd .sol_item').eq(i).find('.item_body').stop().slideUp(300);
                    $('#tech_v'+i)[0].pause();
                }
            }
            $(this).closest('.sol_item').addClass('on');
            $(this).closest('.sol_item').find('.item_body').stop().slideDown(300);
        })
        
        $(window).on('scroll', (e)=>{
            let st = $(window).scrollTop();
            let wh = $(window).innerHeight();

            $('.js-fadeIn').each(function() {
                let this_top = $(this).offset().top;
                //console.log(st+wh,this_top);
                if (st+wh > this_top) {
                    $(this).addClass('on');
                }
            })
        })
        $(window).trigger('scroll');
        return () => {
            $(window).off('scroll');
        }

      },[])
    return ( 
        <>
            <div id="container" >
                <div id="contents" className="lightfield">
                    <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    <span>LIGHT FIELD</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <section className="section full-section lightfield_intro"> 
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header ">
                                        <p className="tit point_circle">
                                            <span>How does it work?</span>
                                        </p>
                                    </div>
                                    <div className="panel-body">
                                        {lang==="kr" ?
                                        <p className="txt">라이트필드 3D는 평면 디스플레이 전면에 정밀하게 계산된 빛의 장(Light Field)을 만드는 방법으로 <br />
                                        3D 안경이나 HMD를 착용하지 않아도 완전한 시차(Parallax)를 제공하는 기술입니다.   </p>
                                        :
                                        <p className="txt">Light Field 3D is a technology of creating a precisely calculated Light Field on the front of flat displays, <br /> 
                                        which offers a complete parallax without wearing 3D glasses or HMD.  </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section lf_info js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="lf_cont_wrap">
                                        <div className="cont_inner type1">
                                            <div className="video_wrap">
                                                <video playsInline autoPlay muted loop id="lf_video1">
                                                    <source src="/resources/images/video/05.Lightfield_view_Multi-View Light Field 3D.mp4" type="video/mp4" />
                                                </video>
                                            </div>
                                            {lang==="kr" ?
                                            <div className="cont_box">
                                                <p className="sub_title_desc">Multi-View Light Field 3D</p>
                                                <p className="sub_title">운동 시차를 평면 디스플레이에 구현 </p>
                                                <p className="sec_text">보는 위치가 바뀌면 보이는 장면도 달라집니다. 한 개의 화면에 50개 이상의 위치에서 촬영한 영상을 동시에 재생하고, 화면 앞으로 라이트필드(빛의 장)을 정밀하게 구현하여, 사용자가 보는 위치에 정확히 맞춰 특정한 영상만 볼 수 있게 합니다. </p>
                                            </div>
                                            :
                                            <div className="cont_box">
                                                <p className="sub_title_desc">Multi-View Light Field 3D</p>
                                                <p className="sub_title">Realize the motion parallax onto flat displays </p>
                                                <p className="sec_text">As viewing positions change, the scene you see changes as well. The technology enables to play images taken from more than 50 locations simultaneously on a single screen, and accurately implements Light Field in front of the screen so that only specific images can be viewed exactly where you see them. </p>
                                            </div>
                                            }
                                        </div>
                                        <div className="cont_inner type2">
                                            <div className="video_wrap">
                                                <video playsInline autoPlay muted loop id="lf_video2">
                                                    <source src="/resources/images/video/05.Lightfield_view_Stereo-View Light Field 3D.mp4" type="video/mp4" />
                                                </video>
                                            </div>
                                            {lang==="kr" ?
                                            <div className="cont_box">
                                                <p className="sub_title_desc">Stereo-View Light Field 3D</p>
                                                <p className="sub_title">양안 시차를 평면 디스플레이에 구현 </p>
                                                <p className="sec_text">양안에 해당하는 두 개의 이미지를 동시에 화면에 재생합니다. 카메라를 이용해 사용자의 시청위치를 정확하게 파악하고, 그 위치에서 가장 선명한 입체영상이 보이도록 라이트필드(빛의 장)를 조정합니다. 사용자의 움직임에도 일관적인 입체영상 시청이 가능합니다.  </p>
                                            </div>
                                            :
                                            <div className="cont_box">
                                                <p className="sub_title_desc">Stereo-View Light Field 3D</p>
                                                <p className="sub_title">Realize the binocular parallax onto flat displays  </p>
                                                <p className="sec_text">Show each two images that match both eyes on the screen at the same time. Play two images simultaneously on the screen(one on the left eye and one on the right eye) to be shown in each eye. Use the camera to track the user's viewing position and adjust the Light Field (Field of Light) to show the clearest stereoscopic image. This enables customers to watch stereoscopic images consistently even when the user is moving.</p>
                                            </div>
                                            }
                                        </div>
                                        <div className="cont_inner type2">
                                            <div className="video_wrap">
                                                <video playsInline autoPlay muted loop id="lf_video3">
                                                    <source src="/resources/images/video/05.Lightfield_view_Dynamic-View Light Field 3D.mp4" type="video/mp4" />
                                                </video>
                                            </div>
                                            {lang==="kr" ?
                                            <div className="cont_box">
                                                <p className="sub_title_desc">Dynamic-View Light Field 3D</p>
                                                <p className="sub_title">양안 시차와 운동 시차를 동시에 구현</p>
                                                <p className="sec_text">양안에 해당하는 두 개의 이미지를 동시에 화면에 재생합니다. 카메라를 이용해 사용자의 시청위치를 정확하게 파악한 뒤 시청위치에 맞는 영상으로 실시간 바꾸어 보여주고, 그 위치에서 입체영상이 가장 선명하게 보이도록 라이트필드를 조정합니다. 사용자의 움직임에 지연 없이 실시간 연동되는 입체영상 시청을 가능하게 합니다. </p>
                                            </div>
                                            :
                                            <div className="cont_box">
                                                <p className="sub_title_desc">Dynamic-View Light Field 3D</p>
                                                <p className="sub_title">Realize binocular and motion parallaxes onto flat display at the same time.</p>
                                                <p className="sec_text">Show each two images that match both eyes on the screen at the same time. Play two images simultaneously on the screen (one on the left eye and one on the right eye) to be shown in each eye. Use the camera to track the user's viewing position and change images corresponding to the viewing position in real time. After this, the Light Field is adjusted for the user to view the clearest stereoscopic image. This enables customers to watch stereoscopic images that are coupled with real-time without latency even when the user is moving.</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section lf_t1 js-fadeIn">
                        <div className="section-inner">
                            {lang==="kr" ?
                            <div className="center-box">
                                <p className="tit">입체영상을 만들고 조정하고 보여주는 <br className='pc_only'/>모든 기술을 보유하고 있습니다 </p>
                                <p className="txt">3D 카메라로 촬영한 영상 혹은 3D 그래픽으로 만들어진 콘텐츠에서 최적의 입체감을 추출하고, 고해상도의 선명한 
                                    라이트필드 3D 디스플레이를 통해 보여줍니다. 모픽은 넓은 범위에서도 일관적인 입체감을 제공하기 위해 사용자의 
                                    시청위치를 실시간으로 추적하고, 추적한 그 위치에서 가장 선명한 입체감이 보이도록 영상을 실시간 조정합니다. </p>
                            </div>
                            :
                            <div className="center-box">
                                <p className="tit">MOPIC possesses all the technologies that <br /> create, adjust, and show stereoscopic images </p>
                                <p className="txt">MOPIC extracts optimal three-dimensional effects from images taken by 3D cameras or 3D graphic content and shows stereoscopic images through high-resolution and vivid Light Field 3D displays. MOPIC tracks the user's viewing position in real time to consistently provide three-dimensional effects even in wide ranges, and adjust images in real time to guarantee the clearest three-dimensional effects in the tracked position. </p>
                            </div>
                            }
                        </div>
                    </section>
                    <section className="section full-section lf_component js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    
                                    <p className="sec_title">KEY Components  </p>
                                    <ul className="lf_comp_key">
                                        <li>
                                            <div className="img_wrap">
                                                <img src="/resources/images/sub/lf_key_img0.png" alt="" />
                                            </div>
                                            {lang==="kr" ?
                                            <div className="key_cont">
                                                <p className="key_tit">3D Capture</p>
                                                <p className="key_txt">3D 카메라로 촬영한 콘텐츠 또는 3D 그래픽으로 만들어진 3D 콘텐츠</p>
                                            </div>
                                            :
                                            <div className="key_cont">
                                                <p className="key_tit">3D Capture</p>
                                                <p className="key_txt">Content taken by 3D cameras or 3D content generated by 3D graphics</p>
                                            </div>
                                            }
                                        </li>
                                        <li>
                                            <div className="img_wrap">
                                                <img src="/resources/images/video/05.Lightfield_component02_re.gif" alt="" />
                                            </div>
                                            {lang==="kr" ?
                                            <div className="key_cont">
                                                <p className="key_tit">MOPIC Hub</p>
                                                <p className="key_txt">3D 콘텐츠에서 사실적인 깊이 정보를 추출해 콘텐츠의 입체감을 최적화하는 API </p>
                                            </div>
                                            :
                                            <div className="key_cont">
                                                <p className="key_tit">MOPIC Hub</p>
                                                <p className="key_txt">APIs that optimize the content's three-dimensional effects by extracting realistic depth information from 3D content.</p>
                                            </div>
                                            }
                                        </li>
                                        <li>
                                            <div className="img_wrap">
                                                <img src="/resources/images/video/05.Lightfield_component03_re.gif" alt="" />
                                                {/* <LottiePlayer url={"05.Lightfield_component03.json"} /> */}
                                            </div>
                                            {lang==="kr" ?
                                            <div className="key_cont">
                                                <p className="key_tit">MOPIC Lib</p>
                                                <p className="key_txt">사용자의 시청위치를 반영해 넓은 범위에서도 일관적인 입체감을 제공하는 소프트웨어 라이브러리 </p>
                                            </div>
                                            :
                                            <div className="key_cont">
                                                <p className="key_tit">MOPIC Lib</p>
                                                <p className="key_txt">Software Library that provides consistent three-dimensional effects even in wide ranges by reflecting the user's viewing position.</p>
                                            </div>
                                            }
                                        </li>
                                        <li>
                                            <div className="img_wrap">
                                                <img src="/resources/images/sub/lf_key_img3.png" alt="" />
                                            </div>
                                            {lang==="kr" ?
                                            <div className="key_cont">
                                                <p className="key_tit">MOPIC Device</p>
                                                <p className="key_txt">세계 최고 수준의 라이트필드 3D 광학 기술이 적용된 디바이스 설계 </p>
                                            </div>
                                            :
                                            <div className="key_cont">
                                                <p className="key_tit">MOPIC Device</p>
                                                <p className="key_txt">Device design with the cutting-edge Light Field 3D optical technology </p>
                                            </div>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section lf_solution js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="sol_header">
                                        <p className="sec_title">KEY Technologies </p>
                                    </div>
                                    <div className="lf_sol_wrap">
                                        <div className="sol_video">
                                            
                                            <Swiper
                                                onSwiper = {setSwiper} 
                                                spaceBetween={0}
                                                slidesPerView={1}
                                                allowTouchMove = {false}
                                                >
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                <video id="tech_v0" playsInline autoPlay muted loop poster='/resources/images/sub/lf_tech_img0.jpg'>
                                                                    <source src="/resources/images/video/05.Lightfield_Key-tech_01.mp4" type="video/mp4" />
                                                                </video>
                                                            </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                <video id="tech_v1" playsInline  muted loop poster='/resources/images/sub/lf_tech_img1.jpg'>
                                                                    <source src="/resources/images/video/05.Lightfield_Key-tech_02.mp4" type="video/mp4" />
                                                                </video>
                                                            </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                <video id="tech_v2" playsInline  muted loop poster='/resources/images/sub/lf_tech_img2.jpg'>
                                                                    <source src="/resources/images/video/05.Lightfield_Key-tech_03.mp4" type="video/mp4" />
                                                                </video>
                                                            </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                            <div className="img_wrap">
                                                                <video id="tech_v3" playsInline  muted loop poster='/resources/images/sub/lf_tech_img3.jpg'>
                                                                    <source src="/resources/images/video/05.Lightfield_Key-tech_04.mp4" type="video/mp4" />
                                                                </video>
                                                            </div>
                                                    </SwiperSlide>
                                                        
                                            </Swiper>

                                            {/* <video playsInline autoPlay muted loop id="lf_video1">
                                                <source src="/resources/images/temp/meta.mp4" type="video/mp4" />
                                            </video> */}
                                        </div>
                                        <div className="sol_acd">
                                            <div className="sol_item on">
                                                <div className="item_head">
                                                    <button onClick={()=> {
                                                        swiper.slideTo(0);
                                                    }}>
                                                        {lang==="kr" ?
                                                        <span className="tit">3D 촬영 및 보정 기술</span>
                                                        :
                                                        <span className="tit">3D Capturing and calibration technology</span>
                                                        }
                                                    </button>
                                                </div>
                                                <div className="item_body" style={{"display":"block"}}>
                                                    {lang==="kr" ?
                                                    <span>
                                                    두 눈을 대신해줄 두 대의 카메라를 배치해 3D 영상을 촬영합니다. 실제 시야와 촬영된 3D 영상이 일치하도록 카메라의 배치를 광학적으로 계산하고, 계산한 수치를 바탕으로 카메라 고정 모듈을 설계합니다. 영상 전송 시스템과 연동하면 실시간 스트리밍도 가능합니다. 
                                                    </span>
                                                    :
                                                    <span>
                                                    Shoot 3D images with two cameras positioned in place of both eyes. Optimally calculate the camera's arrangement in order to match the actual field of view with the 3D image taken, and design the camera's fixed module based on calculated figures. Real-time streaming is also possible when linked with the video transmission system. 
                                                    </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="sol_item">
                                                <div className="item_head">
                                                    <button onClick={()=> {
                                                        swiper.slideTo(1);
                                                    }}>
                                                        {lang==="kr" ?
                                                        <span className="tit">입체감 최적화 기술</span>
                                                        :
                                                        <span className="tit">3D Optimization Technology</span>
                                                        }
                                                    </button>
                                                </div>
                                                <div className="item_body">
                                                    {lang==="kr" ?
                                                    <span>
                                                    3D로 촬영한 영상 또는 3D 그래픽 공간에 두 대 이상의 가상 카메라를 설치해 다시점의 영상을 촬영하고, 촬영된 가상카메라 영상에서 가장 사실적인 깊이 정보를 실시간 추출해 최적의 입체영상으로 보여줍니다. 
                                                    </span>
                                                    :
                                                    <span>
                                                    Two or more virtual cameras are installed in 3D images or 3D graphic spaces to capture multi-view images, extract the most realistic depth information from the virtual camera images in real time, and display them as optimal 3D images.
                                                    </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="sol_item">
                                                <div className="item_head">
                                                    <button onClick={()=> {
                                                        swiper.slideTo(2);
                                                    }}>
                                                        {lang==="kr" ?
                                                        <span className="tit">3D 재생 소프트웨어 차별화</span>
                                                        :
                                                        <span className="tit">3D playback software differentiation</span>
                                                        }
                                                    </button>
                                                </div>
                                                <div className="item_body">
                                                    {lang==="kr" ?
                                                    <span>
                                                    사용자의 시청 위치를 실시간 추적하는 Eye-tracking 기술과, 추적한 위치를 기반으로 화면을 실시간 재배열하는 Real-time Rendering이 포함된 3D Shader를 3D 재생 소프트웨어에 적용합니다. 모픽 3D Shader가 적용된 3D 재생 소프트웨어는 어떤 위치에서도 일관적인 입체감을 제공합니다. 
                                                    </span>
                                                    :
                                                    <span>
                                                    Apply 3D Shader to 3D playback software, which includes Eye-tracking technology that tracks the user's viewing positions in real time, and Real-time Rendering, which rearranges screens in real time based on tracked locations. 3D playback software with MOPIC 3D Shader provides a consistent, three-dimensional effect in any location.
                                                    </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="sol_item">
                                                <div className="item_head">
                                                    <button onClick={()=> {
                                                        swiper.slideTo(3);
                                                    }}>
                                                        {lang==="kr" ?
                                                        <span className="tit">최고 수준 라이트필드 3D 광학 설계</span>
                                                        :
                                                        <span className="tit">The best Light Field 3D optical design</span>
                                                        }
                                                    </button>
                                                </div>
                                                <div className="item_body">
                                                    {lang==="kr" ?
                                                    <span>
                                                    모픽의 라이트필드 3D 광학 설계는 고해상도의 밝은 화면을 제공하고, 넓은 시야각, 그리고 잔상 없이 선명한 입체화면을 제공합니다. 
                                                    </span>
                                                    :
                                                    <span>
                                                    MOPIC's Light Field 3D optical design provides high-resolution bright screens, wide viewing angles, and vivid stereoscopic images without cross talk(X-talk).
                                                    </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn_wrap tac">
                                        {lang==="kr" ?
                                        <Link to={"/"+lang+"/contact/"} className="btn btn_sm btn_blue">기술 문의</Link>
                                        :
                                        <Link to={"/"+lang+"/contact/"} className="btn btn_sm btn_blue">Technology Inquiries</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    


                </div>
            </div>
            <Footer lang={lang}></Footer>
        </>
    ); 
}; 