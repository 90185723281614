const origin = window.location.origin;

let APIURI;

if (origin.indexOf("localhost") > -1) {
    //APIURI = "http://localhost:4002/"
    APIURI = "https://api.mopiclabs.com/dev/board/";
}
else if (origin.indexOf("https://test-dev.mopiclabs.com") > -1 ) {
    APIURI = "https://api.mopiclabs.com/dev/board/";
}
else { //if (origin.indexOf("https://www.mopiclabs.com") > -1 || origin.indexOf("https://mopiclabs.com") > -1 || origin.indexOf("http://www.mopiclabs.com") > -1 || origin.indexOf("http://mopiclabs.com") > -1) {
    APIURI = "https://api.mopiclabs.com/board/";
}


export default APIURI;