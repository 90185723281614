/* eslint-disable */ 
import React, { useEffect, useState } from 'react';

import { useMsal } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest, logoutRequest } from "../../components/auth/config";

function onMsalLogin(instance, type) {
    if (type === "redirect")
    {
        instance.loginRedirect(loginRequest)
        .catch(e => { 
            console.error(e); 
        })
    }
    else if (type === "popup")
    {
        instance.loginPopup(loginRequest)
        .catch(e => { 
            console.error(e); 
        })
    }
}

function onMsalLogout(instance, type) {
    if (type === "redirect") {
      instance.logoutRedirect(logoutRequest)
        .catch(e => {
          console.error(e);
        });
    }
    else if (type === "popup") {
      instance.logoutPopup(logoutRequest)
        .catch(e => {
          console.error(e);
        });
    }
  }
  

export default function Login(prop) {

    const { instance, accounts, inProgress } = useMsal();
    let [user,setUser] = useState('');
    //let user = 'test';
    useEffect(() => {
        if (accounts[0]) {
            window.sessionStorage.setItem('user', accounts[0].name);
            window.sessionStorage.setItem('account', accounts[0].username);
            setUser(window.sessionStorage.getItem('user'));
        }
        else {
            window.sessionStorage.removeItem('user');
            window.sessionStorage.removeItem('account');
        }
    })
    return ( 
        <>
            <section className="section full-section login_wrap">
                <div className="section-inner">
                    <div className="center-box">
                        <div className="content-panel">
                        <AuthenticatedTemplate>
                            <div className="login_stat">
                                <span>안녕하세요 {user}님!</span>
                                <button onClick={() => onMsalLogout(instance, 'redirect')}>[로그아웃]</button>
                                
                            </div>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>

                            <div className="logout_stat">
                                <h3>Sign in</h3>
                                <p>
                                    Microsoft Teams 계정과 연동해주세요.
                                </p>
                                <button className="btn btn_lg btn_blue" onClick={() => onMsalLogin(instance, 'popup')}>로그인</button>
                            </div>

                        </UnauthenticatedTemplate>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}