/* eslint-disable */ 
import React, { useEffect, useState } from 'react'; 
import Footer from '../common/Footer';
import { Link } from 'react-router-dom';
import SlideList from '../modules/SlideList';
import axios from 'axios';
import $ from 'jquery';
import APIURI from './APIURI';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Login from './Login';

export default function BoardList(prop) {
    
    let type=prop.type.split('_')[0];
    let lang=prop.lang;
    let admin = prop.type.indexOf("ADMIN") > -1;

    let [csList, setCsList] = useState([]);
    let [pageAry,setPageAry] = useState([1,1,1,1]); // [currentPage, startPage, endPage, totalPage]
    let [searchTag, setSearchTag] = useState("");
    let [searchCategory, setSearchCategory] = useState("");
    let [searchTitle, setSearchTitle] = useState("");
    let tagAry;
    if (type === "NEWS") {
        tagAry = (lang==="kr") ? ['언론보도'] : ['Press'] ;
    }
    else {
        tagAry = ['3D Video', '3D Streaming', '3D Simulation', 'Review'];
    }
    let pageUnit = 9; // 리스트 한 페이지 최대 게시물 갯수
    let total_num = 0; // 전체 게시물 수

    let searchData = {
        ti:searchTitle,
        tags:searchTag
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        searchData = {
            ti :'',
            tags:'',
            cat :''
        }
        searchList();
        return () => {
            
        }
    }, []);
    

    function searchList() {
        let _url;
        if (admin ) {
            _url =APIURI+type.toLowerCase()+'/'+lang.toLowerCase()+'/0';
        }
        else {
            _url =APIURI+type.toLowerCase()+'/'+lang.toLowerCase()+'/0/opened';
        }
        console.log(type);
        //console.log('searchData',_url, searchData);
        axios.get(_url, {
            params: searchData
        })
        .then(function (response) {
            $('#search').val(searchData.ti);
            console.log(response.data);
            //response.data.reverse();
            total_num = response.data.length;
            let pageNum = 1;
            let totalPage = Math.ceil(total_num/pageUnit);
            let startPage = Math.floor(pageNum/pageUnit)*pageUnit + 1;
            let endPage = startPage + pageUnit - 1;
            endPage = (endPage > totalPage) ? totalPage : endPage;
            setPageAry([pageNum, startPage, endPage, totalPage]);
            setCsList(response.data); 
        })


    }
    function decodeDate(date) {
        var _date = new Date(date);
        return _date.getFullYear() + "-" + toDigit(_date.getMonth()+1) + "-"+ toDigit(_date.getDate());
    }
    function devodeContent(_content) {
        return $(_content).text().replaceAll('<br>',' ').substring(0,150);
    }
    function toDigit(num) {
        return (num<10) ? "0"+num: num;
    }
    

    function paging() {
        var resultAry = [];
        if (pageAry[0] > 1) {
            resultAry.push(<button className="btn_page_prev" key={"pagePrev"} onClick={()=> {
                let p = (pageAry[1] >1) ? pageAry[1]-1 : 1;
                let temp = [...pageAry];
                temp[0]= p;
                setPageAry(temp);
            }}><span className="blind">Prev</span></button>);
        }
        else {
            resultAry.push(<button className="btn_page_prev" key={"pagePrev"} disabled><span className="blind">Prev</span></button>);
        }
        for(var i=pageAry[1]; i<=pageAry[2]; i++) {
            if (i===pageAry[0]) {
                resultAry.push(<button className="btn_page_num on" key={"page"+i}>{i}</button>);
            }
            else {
                resultAry.push(<button className="btn_page_num" data-key={i} key={"page"+i} onClick={(e)=> {
                    let temp = [...pageAry];
                    temp[0]=parseInt(e.currentTarget.dataset.key);
                    setPageAry(temp);
                }}>{i}</button>);
            }
        }
        if (pageAry[0]===pageAry[3] ) {
            resultAry.push(<button className="btn_page_next" key={"pageNext"} disabled><span className="blind">Next</span></button>);
        }
        else {
            resultAry.push(<button className="btn_page_next" key={"pageNext"} onClick={()=> {
                let p = (pageAry[2] < pageAry[3]) ? pageAry[2]+1 : pageAry[2];
                let temp = [...pageAry];
                temp[0]=p;
                setPageAry(temp);
            }}><span className="blind">Next</span></button>);
        }
        return resultAry;
    }
   
    function listItems() {
        if (csList.length ===0) {
            return null;
        }
        var result = [];
        let startItemNum = (pageAry[0]-1)*pageUnit;
        let endItemNum = (pageAry[0] === pageAry[3]) ? csList.length : (pageAry[0])*pageUnit;
        for (let i=startItemNum; i<endItemNum; i++ ) {
            let row = csList[i];
            result.push(<li key={"list"+i} className={row.is_opened_post ? "opened" : "unopened"}>
                <div className="cs_item">
                    <Link to={'/' + lang.toLowerCase() + "/"+prop.type.toLowerCase() + "/view/"+row.post_id}>
                        <div className="cont_wrap">
                            <p className="category">{row.post_category}</p>
                            <p className="title">{row.post_title.replaceAll("&#39;","'")}</p>
                            {row.post_contents ? 
                            <p className="txt">{devodeContent(row.post_contents)}</p>
                            : null }
                        </div>
                    </Link>
                </div>
            </li>);
        }
        
        return result;
    }
    function ResultContainer() {
        return (
            <>
            
                    {/* <section className="section full-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="cs_slide">
                                        <SlideList type={prop.type} lang={lang}></SlideList>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {csList ?
                    <section className="section basic-section list_container board_list">
                        <div className="section-inner">
                            <div className="list_header">
                                
                                <div className="list_search">
                                    {lang==="kr" ? 
                                    <input type="text" id="search" className='input_search' placeholder='검색어를 입력하세요.' onChange={(e)=> {
                                        searchData.ti =e.currentTarget.value;
                                    }} onKeyDown={(e)=> {
                                        if (e.key==="Enter") {
                                            searchData.tags ='';
                                            searchList()
                                        }
                                    }} />
                                    :
                                    
                                    <input type="text" id="search" className='input_search' placeholder='Search' onChange={(e)=> {
                                        searchData.ti =e.currentTarget.value;
                                    }} onKeyDown={(e)=> {
                                        if (e.key==="Enter") {
                                            searchData.tags ='';
                                            searchList()
                                        }
                                    }} />
                                    }
                                    <button className="btn_search" onClick={()=> {
                                        
                                        searchData.tags ='';
                                        searchList()
                                    }}><span className="blind">SEARCH</span></button>
                                </div>
                            </div>
                            <div className="list_body">
                                <ul className="cs_list">
                                    {listItems()}
                                </ul>
                            </div>
                            <div className="list_footer">
                                { paging() }
                                {admin ? 
                                <div className='mt30 tar'>
                                    <Link to={"/"+lang+"/"+prop.type.toLowerCase()+"/write/"} className='btn btn_sm btn_blue '>게시물 등록</Link>
                                </div>
                                : null }
                            </div>
                            
                        </div>
                    </section>
                    : null }
            </>
        )
    }


    return ( 
        <>
         
            <div id="container" >
                <div id="contents" className={ type.toLowerCase() }>
                    <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    { type==="SUPPORT" ?
                                        <span>SUPPORT</span>
                                        : null
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                {admin ? 
                <>
                    <Login></Login>
                    <AuthenticatedTemplate>
                        <ResultContainer></ResultContainer>
                    </AuthenticatedTemplate>
                </>
                :
                    <ResultContainer></ResultContainer>
                }

                </div>
            </div>
            
            <Footer lang={lang}></Footer>
        </>
    ); 
}; 