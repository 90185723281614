/* eslint-disable */ 
import React, { useEffect } from 'react'; 
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
//import CustomScroll from 'react-custom-scroll';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import $ from 'jquery';


export default function Company(prop) {
    let lang = prop.lang;
    let [historyView, setHistoryView] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);

        $(window).on('scroll', (e)=>{
            let st = $(window).scrollTop();
            let wh = $(window).innerHeight();

            $('.js-fadeIn').each(function() {
                let this_top = $(this).offset().top;
                //console.log(st+wh,this_top);
                if (st+wh > this_top) {
                    $(this).addClass('on');
                }
            })
        })
        $(window).trigger('scroll');
        return () => {
            $(window).off('scroll');
        }
    }, []);

    let [historyImg, setHistoryImg] = useState("/resources/images/sub/transparent.png");

    let px, py;
    $(document).on('mousemove',function(e){ 
        px = e.pageX;
        py = e.pageY;
    }); 
    $(document).trigger('mousemove')
    function showImage(e) {
        $('.js-history_image').stop().fadeIn(300);
        $('.js-history_image').css({'top': $(e.target).offset().top - $('.history_wrap').offset().top - 70})
    }
    function hideImage() {
        $('.js-history_image').stop().fadeOut(300);
    }
    return ( 
        <>
            <div id="container" className={lang}>
                <div id="contents" className="company">
                    <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    <span>COMPANY </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <section className="section full-section company_intro">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header ">
                                        <p className="tit point_circle">
                                        <span>Most Immersively Yours </span>
                                        </p>
                                    </div>
                                    <div className="panel-body">
                                        {lang === "kr" ?
                                        <p className="txt">
                                            모픽은 현실의 나와 다른 장소, 다른 세계, 다른 차원을 사실적으로 연결해 줍니다. <br />
                                            마치 디스플레이의 프레임 너머 공간을 들여다보는 것 같은 시각적 경험을 제공합니다.  
                                        </p>
                                        : 
                                        <p className="txt">
                                            MOPIC immersively connects different places, worlds and dimensions. <br />
                                            MOPIC provides vivid visual experiences as if you’re looking into space beyond the frame of the display.
                                        </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section full-section company_info js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header ">
                                        {lang === "kr" ?
                                        <p className="tit point_circle">
                                            모픽은 대중적인 솔루션을 공급하는 <br className='pc_only'/> 라이트필드 3D 기술 기업입니다
                                        </p>
                                        : 
                                        <p className="tit point_circle">
                                            MOPIC is a Light Field 3D technology company <br /> that provides ground-breaking universal solutions
                                        </p>
                                        }
                                    </div>
                                    <div className="panel-body">
                                        {lang === "kr" ?
                                        <p className="txt">
                                            삼성전자에서 ‘무안경 3D 디스플레이’를 연구하던 연구원들이 스핀오프하여 모픽을 설립하였습니다. <br />
                                            모픽의 미션은 가장 대중적인 방법으로 최고의 기술력을 제공하는 라이트필드 3D 기술 기업이 되는 것입니다. <br />
                                            모픽은 세계 최초로 스마트폰에 탈부착이 가능한 보호 케이스 형태의 라이트필드 3D 스크린과 전용 앱을 출시하였으며, <br />
                                            3D 전용 디바이스 성능 이상의 라이트필드 3D를 구현했습니다.   
                                        </p>
                                        :
                                        <p className="txt">
                                            MOPIC’s technology is developed by former Samsung Electronics glasses-free 3D display researchers. <br />
                                            MOPIC's mission is to become a Light Field-specialized 3D company that provides state-of-the-art technology. <br />
                                            We released the first-ever Light Field 3D smartphone case, Snap3D, that attaches to smartphones and their companion apps.  <br />
                                            The quality of Snap3D is beyond the function of 3D-exclusive devices.
                                        </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section company_history js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header">
                                        <p className="tit">
                                            HISTORY
                                        </p>
                                    </div>
                                    <div className="panel-body">
                                        
                                        <div className={ historyView ? "history_wrap view" : "history_wrap"}>
                                        {lang === "kr" ?
                                        <SimpleBar style={{ maxHeight: 600 }} autoHide={false}>
                                            <dl>
                                                <dt>2024</dt>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2405_exhib.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">05</span>
                                                    <span className="txt">SID 2024에서 iPhone 및 Galaxy를 위한 3D 솔루션 공개</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2405_exhib.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2404_camera.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">04</span>
                                                    <span className="txt">iPhone 15 Pro를 위한 3D 촬영 및 시청 솔루션 개발 </span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2404_camera.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>

                                                <dt>2023</dt>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2311_oi.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">11</span>
                                                    <span className="txt">현대자동차 오픈이노베이션 라운지에서 차량용 3D 디스플레이 솔루션 공개</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2311_oi.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2207_01.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">07</span>
                                                    <span className="txt">중소기업벤처부 ‘초격차 스타트업 1000+ 프로젝트’에 선정</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2207_01.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2306_nextaward.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">06</span>
                                                    <span className="txt">산업은행 주관 'Nextrise Awards'에서 'Grand Innovation Prize (대상)' 수상</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2306_nextaward.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">현대자동차 ZER01NE 협업 스타트업으로 선정</span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2301_BSNH.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">01</span>
                                                    <span className="txt">분당서울대병원 스마트 시뮬레이션 센터에 라이트필드 3D 모니터링 시스템 및 3D 현미경 시스템 공급</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2301_BSNH.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>

                                                <dt>2022</dt>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2212_Cnet.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">12</span>
                                                    <span className="txt">MOPIC Dhaula, Cnet Korea 'Editor's Choice'에 선정</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2212_Cnet.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2210_Unity.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">10</span>
                                                    <span className="txt">MOPIC SDK for Unity 공개 (closed beta)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2210_Unity.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2206_Chooyu.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">06</span>
                                                    <span className="txt">32" 라이트필드 3D 디스플레이 'Cho Oyu' 공개 (Infocomm 2022)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2206_Chooyu.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2206_Lhotse.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">06</span>
                                                    <span className="txt">65" 라이트필드 3D 디스플레이 'Lhotse' 공개 (Infocomm 2022)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2206_Lhotse.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                {/* <dd>
                                                    <span className="month">06</span>
                                                    <span className="txt">Mercedes Benz와 협업을 위한 NDA 체결</span>
                                                </dd> */}
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2202_casavatyo.jpg");
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">02</span>
                                                    <span className="txt">유네스코 문화유산 Casa Batllo에 스마트 사이니지 실증</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2202_casavatyo.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2021</dt>
                                                <dd>
                                                    <span className="month">12</span>
                                                    <span className="txt">Kalmar 및 Elisa와 물류컨테이너 원격제어 실증사업 계약 체결 </span>
                                                </dd>
                                                {/* <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2106_Solectrix.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">10</span>
                                                    <span className="txt">LG 디스플레이와 협업을 위한 NDA 체결</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2106_Solectrix.jpg" alt="" />
                                                    </span>
                                                </dd> */}
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2109_MS.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">09</span>
                                                    <span className="txt">Microsoft ‘스타트업 프로그램 기업’으로 선정</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2109_MS.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2109_hyundai.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">09</span>
                                                    <span className="txt">현대중공업 및 KT와 모픽 LF 3D 시스템을 활용한 미래형 자율운항선박 원격제어 실증사업 계약 체결</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2109_hyundai.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                {/* <dd>
                                                    <span className="month">08</span>
                                                    <span className="txt">Solectrix에 디지털 현미경용 3D 디스플레이 솔루션 공급</span>
                                                </dd> */}
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2106_smartcity.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">06</span>
                                                    <span className="txt">바르셀로나 스마트시티 해외 실증 테스트베드 기업으로 선정</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2106_smartcity.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2105_unicon.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">05</span>
                                                    <span className="txt">중소기업벤처부 ‘아기 유니콘 기업’으로 선정</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2105_unicon.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">StartUs Insights ‘세계 5대 라이트 필드 기업'으로 선정</span>
                                                </dd>
                                                <dd>
                                                    <span className="month">04</span>
                                                    <span className="txt">Windows OS용 MOPIC Mirror 공개</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2020</dt>
                                                <dd>
                                                    <span className="month">07</span>
                                                    <span className="txt">MOPIC Creator 공개</span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2001_CES.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">01</span>
                                                    <span className="txt">MOPIC Signage 및 MOPIC Personal System 공개 (CES2020)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2001_CES.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2019</dt>
                                                {/* <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">Mercedes Benz와 샘플 공급을 위한 NDA 체결</span>
                                                </dd> */}
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1905_samsung.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">05</span>
                                                    <span className="txt">삼성전자에 갤럭시 5G 스마트폰 프로모션용 3D VR 뷰어 케이스 공급</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1905_samsung.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1904_LG.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">04</span>
                                                    <span className="txt">LG U+ 5G VR 플랫폼 무안경 모바일 3D 디스플레이 솔루션 공급업체로 채택</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1904_LG.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1903_insta.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">03</span>
                                                    <span className="txt">Insta360 모바일용 무안경 3D 디스플레이 솔루션 공급업체로 채택</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1903_insta.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1901_CES.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">01</span>
                                                    <span className="txt">스마트폰 3D VR 뷰어 케이스 Snap3D로 미국 CES 혁신상 수상 </span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1901_CES.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2018</dt>
                                                <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">MOPIC 중국 상하이 법인 설립</span>
                                                </dd>
                                                <dd>
                                                    <span className="month">01</span>
                                                    <span className="txt">Android, iOS, Windows OS용 Unity 3D SDK 개발</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2017</dt>
                                                <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">AR 및 VR 콘텐츠용 몰입형 시각 기술 개발</span>
                                                </dd>
                                                <dd>
                                                    <span className="month">03</span>
                                                    <span className="txt">안드로이드/iOS용 MOPIC 플레이어 정식 출시 </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2016</dt>
                                                <dd>
                                                    <span className="month">09</span>
                                                    <span className="txt">복잡한 광학 파라미터를 사용자가 직접 간단하게 정렬할 수 있는 광학 알고리즘 개발</span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1607_IFA.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">07</span>
                                                    <span className="txt">Android 및 Windows OS용 MOPIC 플레이어 공개 (IFA 2016) </span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1607_IFA.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2015</dt>
                                                <dd>
                                                    <span className="month">11</span>
                                                    <span className="txt">모픽 창립 (삼성전자 SPIN-OFF 8호)</span>
                                                </dd>
                                            </dl>
                                        </SimpleBar>
                                        :
                                        <SimpleBar style={{ maxHeight: 600 }} autoHide={false}>
                                            <dl>
                                                <dt>2024</dt>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2405_exhib.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">05</span>
                                                    <span className="txt">Unveiled 3D Solution for iPhone and Galaxy (closed beta)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2405_exhib.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2404_camera.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">04</span>
                                                    <span className="txt">Developed 3D capture and viewing solutions for iPhone 15 Pro</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2404_camera.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>

                                                <dt>2023</dt>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2311_oi.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">11</span>
                                                    <span className="txt">Unveiled 3D Display solution for Car (Hyundai Motors' Open Innovation Lounge)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2311_oi.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2306_nextaward.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">06</span>
                                                    <span className="txt">Won 'Grand Innovation Prize' at Korea Development Bank's Nextrise Awards</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2306_nextaward.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">Selected as "ZERO01NE Startup” by Hyundai Motors</span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2301_BSNH.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">01</span>
                                                    <span className="txt">Supplied Light Field 3D Monitoring system and 3D Microscopic system to Smart Simulation Center at Bundang Seoul National Hospital</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2301_BSNH.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2022</dt>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2212_Cnet.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">12</span>
                                                    <span className="txt">MOPIC Dhaula selected as 'Editor's Choice' by Cnet Korea</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2212_Cnet.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2210_Unity.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">10</span>
                                                    <span className="txt">Unveiled MOPIC SDK for Unity (closed beta)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2210_Unity.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2206_Chooyu.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">06</span>
                                                    <span className="txt">Unveiled 32" Light Field 3D Display 'Cho Oyu' (Infocomm 2022)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2206_Chooyu.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2206_Lhotse.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">06</span>
                                                    <span className="txt">Unveiled 65" Light Field 3D Display 'Lhotse' (Infocomm 2022)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2206_Lhotse.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2202_casavatyo.jpg");
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">02</span>
                                                    <span className="txt">Conducted the PoC for Smart Signage in Casa Batllo, an UNESCO Cultural Heritage</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2202_casavatyo.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2021</dt>
                                                <dd>
                                                    <span className="month">12</span>
                                                    <span className="txt">Signed contracts with Kalmar and Elisa to demonstrate the remote-control usage of logistics containers </span>
                                                </dd>
                                                {/* <dd>
                                                    <span className="month">10</span>
                                                    <span className="txt">Signed NDA to collaborate with LG Display</span>
                                                </dd> */}
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2109_MS.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">09</span>
                                                    <span className="txt">Selected as "Startup Program Company” by Microsoft</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2109_MS.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2109_hyundai.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">09</span>
                                                    <span className="txt">Signed a contract with Hyundai Heavy Industries and KT to  conduct the Proof of Concept(PoC) for the remote-control usage of future autonomous vessels using LF 3D systems</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2109_hyundai.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                {/* <dd>
                                                    <span className="month">08</span>
                                                    <span className="txt">Supplied 3D Display Solution to Solectrix for Digital Microscope</span>
                                                </dd> */}
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2106_smartcity.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">06</span>
                                                    <span className="txt">Selected as a "Barcelona smart city overseas test bed company"</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2106_smartcity.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2105_unicon.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">05</span>
                                                    <span className="txt">Selected as one of Korea's “K-Unicorn Project” by the Ministry of SMEs and Startups(MSS)</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2105_unicon.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">Selected as one of the global top five Light-Field Display Companies by StartUs Insights</span>
                                                </dd>
                                                <dd>
                                                    <span className="month">04</span>
                                                    <span className="txt">Announced its Mirror for Windows OS</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2020</dt>
                                                <dd>
                                                    <span className="month">07</span>
                                                    <span className="txt">Released MOPIC Creator</span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_2001_CES.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">01</span>
                                                    <span className="txt">Unveiled its Signage and Personal Systems in the CES 2020</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_2001_CES.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2019</dt>
                                                {/* <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">Signed an NDA with Mercedes Benz</span>
                                                </dd> */}
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1905_samsung.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">05</span>
                                                    <span className="txt">Supplied 3D VR viewer cases to Samsung Electronics for Galaxy 5G smartphone promotions</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1905_samsung.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1904_LG.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">04</span>
                                                    <span className="txt">Selected as a provider of glasses-free mobile 3D display solutions for 5G VR platforms by LG U+</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1904_LG.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1903_insta.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">03</span>
                                                    <span className="txt">Adopted as a provider of glasses-free 3D display solutions by Insta360</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1903_insta.jpg" alt="" />
                                                    </span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1901_CES.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">01</span>
                                                    <span className="txt">Won the CES Innovation Award with Snap3D, a smartphone case 3D Viewer</span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1901_CES.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2018</dt>
                                                <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">Established Its corporate branch in Shanghai, China</span>
                                                </dd>
                                                <dd>
                                                    <span className="month">01</span>
                                                    <span className="txt">Developed Unity SDKs for Android, iOS, and Windows OS</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2017</dt>
                                                <dd>
                                                    <span className="month">05</span>
                                                    <span className="txt">Developed Immersive visual technologies for AR and VR content</span>
                                                </dd>
                                                <dd>
                                                    <span className="month">03</span>
                                                    <span className="txt">Officially released MOPIC Player for Android and iOS </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2016</dt>
                                                <dd>
                                                    <span className="month">09</span>
                                                    <span className="txt">Developed An optical algorithm that allows customers to simply arrange complex optical parameters</span>
                                                </dd>
                                                <dd className="hasImage" onMouseOver={(e)=>{
                                                    setHistoryImg("/resources/images/sub/History_1607_IFA.jpg")
                                                    showImage(e);
                                                }} onMouseOut={()=>{
                                                    hideImage();
                                                }}>
                                                    <span className="month">07</span>
                                                    <span className="txt">Unveiled MOPIC Player for Android and Windows OS (IFA 2016) </span>
                                                    <span className="img_wrap">
                                                        <img src="/resources/images/sub/History_1607_IFA.jpg" alt="" />
                                                    </span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>2015</dt>
                                                <dd>
                                                    <span className="month">11</span>
                                                    <span className="txt">Established the 8th offshoot of Samsung Electronics</span>
                                                </dd>
                                            </dl>
                                        </SimpleBar>
                                        }
                                        <div className={ historyView ? "btn_wrap hide" : "btn_wrap tac"}>
                                            <button className="btn_arrow_down" onClick={()=>{
                                                setHistoryView(true);
                                            }}>Learn More</button>
                                        </div>
                                        </div>
                                        <div className="history_image">
                                            <div className="img_wrap js-history_image" style={{display:'none'}}>
                                                <img src={historyImg} alt="history" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section full-section company_culture js-fadeIn">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header">
                                        <p className="tit">
                                            PRINCIPLE
                                        </p>
                                    </div>
                                    <div className="panel-body">
                                        {lang === "kr" ?
                                        <p className="txt">모픽은 아래의 다섯 가지 원칙을 따릅니다. </p>
                                        :
                                        <p className="txt">MOPIC follows five principles as below.</p>
                                        }
                                        <div className="cultrue_wrap">
                                            {lang === "kr" ?
                                            <ul className="culture_list">
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>고객 집착</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        모든 일을 ‘고객의 관점’에서 봅니다. 고객 경험에 지속적인 가치를 부여하기 위해 크고 장기적인 사고를 통한 방법을 모색합니다.  
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>성과 집중</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        더 광범위하고 의미 있는 성과에 집중합니다. 이 가치는 모픽이 수행하는 모든 프로세스와 창출해내는 제품 및 서비스에 해당됩니다. 
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>우수함 추구</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        품질, 역량, 태도, 문화, 진정성 등 종합적인 면에서 탁월해지기 위해 노력합니다. 모픽은 늘 높은 표준을 추구합니다. 
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>기업가 정신</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        모험심, 행동, 리더십, 적응력, 열정, 탐구, 근면, 영향, 목적, 효율, 속도 및 승리를 장려하고 추구합니다.  
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>상호 존중</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        팀의 성공을 장려하고, 서로에게 영감을 주고 존중합니다.  
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                            :
                                            <ul className="culture_list">
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>Customer obsession</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        See everything from “the customer's perspective”. Explore ways to bring consistent values to the customer's experiences through big-picture and long-term thinking. 
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>Focus on Performance</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        Focus on broader and more meaningful performances. This value corresponds to all the processes performed by MOPIC and to the products and services it creates.
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>Pursuit of Excellence</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        Do our utmost efforts to excel in various aspects such as quality, competence, attitude, culture, authenticity. MOPIC always pursues a high standard.
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>Entrepreneurship</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        Encourage and pursue adventure, actions, leadership, adaptability, passion, inquiries, diligence, influence, purposes, efficiency, speed, and victory.  
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="c_tit_wrap">
                                                        <div className="c_tit">
                                                            <span>Mutual Respect</span>
                                                        </div>
                                                    </div>
                                                    <div className="c_cont">
                                                        <span className='txt'>
                                                        Encourage team success, inspire and respect each other.
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <section className="section full-section company_team">
                        <div className="section-inner">
                            <div className="center-box">
                                {lang === "kr" ?
                                <div className="content-panel team_intro js-fadeIn">
                                    <p className="tit">
                                        ‘팀 파워’로 답을 찾을 수 있다는 <br /> 자신감을 가지고 있습니다 
                                    </p>
                                    <p className="txt">
                                        모픽의 가장 큰 원동력은 작지만 강한 팀 파워에서 나옵니다. <br />
                                        소프트웨어, 하드웨어 그리고 사업 개발까지 각 분야의 전문가들과 함께 하고 있습니다. 
                                    </p>
                                </div>
                                :
                                <div className="content-panel team_intro js-fadeIn">
                                    <p className="tit">
                                        We are confident that <br />we can find the answer with "Team Power"
                                    </p>
                                    <p className="txt">
                                        MOPIC's biggest driving force comes from a small but strong team power. <br />
                                        Experts from various fields, including software, hardware, and business development are joining us.
                                    </p>
                                </div>
                                }
                                
                                <div className="btn_box js-fadeIn career_btn">
                                        {lang==="kr" ?
                                        <Link to="" className="btn btn_blue btn_sm" onClick={()=>{
                                            window.open('https://www.notion.so/2022-MOPIC-25de2efc906f427eb82ccff8192ce350','_blank')
                                        }}>채용 공고</Link>
                                        :
                                        <Link to="" className="btn btn_blue btn_sm" onClick={()=>{
                                            window.open('https://www.notion.so/2022-MOPIC-25de2efc906f427eb82ccff8192ce350','_blank')
                                        }}>Career</Link>
                                        }
                                    </div>
                            </div>
                        </div>
                        
                        
                    </section>


                </div>
            </div>
            <Footer lang={lang} className={"mt0"}></Footer>
        </>
    ); 
}; 