// eslint-disable
import React , {useEffect, useRef} from "react";
import lottie from "lottie-web";

export default function LottiePlayer(prop) {
     //lottie
  
  const likecontainer = useRef(); 
  useEffect(()=>{
    // let wrap = document.getElementsByClassName('lottie_wrap');
    // wrap.remove();
    lottie.loadAnimation({
      container: likecontainer.current,
      renderer: 'svg',
      loop: true,
      autoplay:true,
      animationData:require("../../images/lottie/" + prop.url)
     
    })
  },[prop.url])
    return(
            <div className="lottie_wrap" ref={likecontainer}></div>
          
    )
}

