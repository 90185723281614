import React, { useEffect } from 'react'; 
import Footer from '../common/Footer';



export default function Privacy(prop) {
    let lang = prop.lang ? prop.lang : "kr";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return ( 
        <>
            <div id="container" >
                <div id="contents" className="contact">
                    <div className="page_title-section">
                        <div className="section-inner">
                            <div className="center-box">
                                <p className="page_title">
                                    <span>개인정보처리방침 </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <section className="section full-section privacy_wrap">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header ">
                                        <p className="tit point_circle white_circle">
                                        개인정보처리방침
                                        </p>
                                    </div>
                                    <div className="panel-body">
                                        <div className="privacy-contents">
                                            <h3>MPlayer3D 개인정보처리방침</h3>
                                            <p>
                                                Mplayer3D 개인정보방침이란 Mplayer3D 어플리케이션을 이용하는 이용자들이 안심하고 서비스를 제공 받을 수 있도록 Mplayer3D가 준수해야 할 개인정보보호 지침을 말합니다.<br />
                                                개인정보 처리방침은 정부의 법률 및 지침의 변경과 정책 변화에 따라 변경될 수 있습니다. 
                                            </p>
                                            <p>
                                                1. 수집하는 개인정보 항목<br />
                                                2. 개인정보 보호 책임자 및 담당자<br />
                                                3. 개인정보 처리 방침의 적용범위<br />
                                                4. 고지의 의무
                                            </p>
                                            <ol>
                                                <li>
                                                    <h4>수집하는 개인정보 항목</h4>
                                                    <p>Mplayer3D는 사용자의 개인정보를 수집, 보관하지 않습니다.</p>
                                                </li>
                                                <li>
                                                    <h4>개인정보 보호 책임자 및 담당자</h4>
                                                    <p>MOPIC은 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.</p>
                                                    <ul>
                                                        <li>
                                                            <h5>개인정보 보호 책임자</h5>
                                                            <dl>
                                                                <dt>이름:</dt>
                                                                <dd>신창봉</dd>
                                                                <dt>직위:</dt>
                                                                <dd>대표이사</dd>
                                                                <dt>전화:</dt>
                                                                <dd>031-299-6835</dd>
                                                                <dt>E-Mail:</dt>
                                                                <dd>cbshin@mopic.co.kr</dd>
                                                            </dl>
                                                        </li>
                                                        <li>
                                                            <h5>개인정보 보호 담당자</h5>
                                                            <dl>
                                                                <dt>이름:</dt>
                                                                <dd>김기영</dd>
                                                                <dt>직위:</dt>
                                                                <dd>과장</dd>
                                                                <dt>전화:</dt>
                                                                <dd>031-299-6835</dd>
                                                                <dt>E-Mail:</dt>
                                                                <dd>gykim@mopic.co.kr</dd>
                                                            </dl>
                                                        </li>
                                                    </ul>
                                                    <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br /><br />1. 개인정보 침해신고센터 (privacy.kisa.or.kr / 국번없이 118)<br />2. 대검찰청 사이버수사과 (www.spo.go.kr / 02-3480-2000)<br />3. 경찰청 사이버안전국 (www.ctrc.go.kr / 국번 없이 182)</p>
                                                </li>
                                                <li>
                                                    <h4>개인정보 처리 방침의 적용범위</h4>
                                                    <p>본 개인정보 처리 방침은 Mplayer3D 어플리케이션에 적용되며, 다른 서비스에 대해서는 별개의 개인정보처리방침이 적용될수 있습니다.</p>
                                                </li>
                                                <li>
                                                    <h4>고지의 의무</h4>
                                                    <p>현 개인정보 처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의 ‘공지사항’을 통해 고지합니다.
                                                        
                                                    </p>
                                                    <dl>
                                                            <dt>공고일자:</dt>
                                                            <dd>2018년 10월 19일</dd>
                                                            <dt>시행일자:</dt>
                                                            <dd>2018년 10월 19일</dd>
                                                        </dl>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer lang={lang} className="mt0"></Footer>
        </>
    )
}