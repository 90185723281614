import React, { useEffect, useRef } from 'react'; 
import Footer from '../common/Footer';
import emailjs from '@emailjs/browser';
import { useState } from 'react';
import $ from 'jquery';


export default function Contact(prop) {
    const lang = prop.lang;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    let [sended, setSended] = useState(false);
    const form = useRef();

    let templateParams = {
        sales : 'Sales',
        user_name : '',
        tel : '',
        company : '',
        user_email : '',
        message : ''
    };
      const sendEmail = (e) => {
        e.preventDefault();
        if (!templateParams.user_name) {
            $('input[name="user_name"]').focus();
            if (lang==="kr") {
                alert('이름을 입력해주세요'); 
            }
            else {
                alert("The field 'Name' is required."); 
            }
            return false;
        }
        if (!templateParams.user_email) {
            $('input[name="user_email"]').focus();
            if (lang==="kr") {
                alert('이메일을 입력해주세요'); 
            }
            else {
                alert("The field 'E-mail' is required."); 
            }
            return false;
        }
        if (!templateParams.message) {
            $('textarea[name="message"]').focus();
            if (lang==="kr") {
                alert('메세지를 입력해주세요');
            }
            else {
                alert("The field 'Message' is required."); 
            }
            return false;
        }
         
        //emailjs.send('service_94xp6yc', 'template_e1l6fex', templateParams, 'hTl3KSgNf6lrUdTlm')
        emailjs.send('service_83tdmit', 'template_w1p55vs', templateParams, 'wT1_RJk7YLSJ7_z35')
            .then(function(response) {
               setSended(true);
               $('input, textarea').val('');
               templateParams = {
                    sales : 'Sales',
                    user_name : '',
                    tel : '',
                    company : '',
                    user_email : '',
                    message : ''
                };
            }, function(error) {
               console.log('FAILED...', error);
            });

      };

    
    return ( 
        <>
            <div id="container" >
                <div id="contents" className="contact">
                    <section className="section full-section contact_wrap">
                        <div className="section-inner">
                            <div className="center-box">
                                <div className="content-panel">
                                    <div className="panel-header ">
                                        <p className="tit point_circle white_circle">
                                        Contact MOPIC 
                                        </p>
                                        {lang ==="kr" ?
                                        <p className="txt">궁금한 점이 있으세요? 아래 양식에 문의를 남겨 주시면 곧 답변 드리겠습니다.</p>
                                        :
                                        <p className="txt">Do you have any questions? Please leave your inquiry on the form below and we will reply shortly.</p>
                                        
                                        }
                                    </div>
                                    <div className="panel-body">
                                        {/* <form ref={form} onSubmit={sendEmail}> */}
                                        <form ref={form} onSubmit={sendEmail}>
                                            <div className="consulting_box">
                                                


                                                <div className="consult_cont ">
                                                    <p className="label">Select <em className="star">*</em></p>
                                                    <div className="sel_wrap">
                                                        <div className="sel_box trp radio-box">
                                                            <input type="radio" name="sales" id="sel0" value="Sales" defaultChecked onChange={(e)=> {
                                                                templateParams.sales = e.currentTarget.value;
                                                            }}/>
                                                            <i></i>
                                                            <label htmlFor="sel0">Sales</label>
                                                        </div>
                                                        <div className="sel_box trp radio-box">
                                                            <input type="radio" name="sales" id="sel1" value="Collaboration" onChange={(e)=> {
                                                                templateParams.sales = e.currentTarget.value;
                                                            }} />
                                                            <i></i>
                                                            <label htmlFor="sel1">Collaboration</label>
                                                        </div>
                                                        <div className="sel_box trp radio-box">
                                                            <input type="radio" name="sales" id="sel2" value="Support" onChange={(e)=> {
                                                                templateParams.sales = e.currentTarget.value;
                                                            }} />
                                                            <i></i>
                                                            <label htmlFor="sel2">Support</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {lang ==="kr" ?
                                                <div className="consult_cont col_half">
                                                    <label htmlFor="cs_name" className="label_text">이름<em className="must star">*</em></label>
                                                    <input type="text" id="cs_name" name="user_name" placeholder="이름을 입력해주세요." onChange={(e)=> {
                                                        templateParams.user_name = e.currentTarget.value;
                                                    }} />
                                                </div>
                                                :
                                                <div className="consult_cont col_half">
                                                    <label htmlFor="cs_name" className="label_text">Name<em className="must star">*</em></label>
                                                    <input type="text" id="cs_name" name="user_name" placeholder="" onChange={(e)=> {
                                                        templateParams.user_name = e.currentTarget.value;
                                                    }} />
                                                </div>
                                                }
                                                {lang ==="kr" ?
                                                <div className="consult_cont col_half">
                                                    <label htmlFor="cs_tel" className="label_text">연락처</label>
                                                    <input type="text" id="cs_tel" name="tel" placeholder="연락처를 입력해주세요." onChange={(e)=> {
                                                        templateParams.tel = e.currentTarget.value;
                                                    }} />
                                                </div>
                                                :
                                                <div className="consult_cont col_half">
                                                    <label htmlFor="cs_tel" className="label_text">Contact</label>
                                                    <input type="text" id="cs_tel" name="tel" placeholder="" onChange={(e)=> {
                                                        templateParams.tel = e.currentTarget.value;
                                                    }} />
                                                </div>
                                                }
                                                {lang ==="kr" ?
                                                <div className="consult_cont col_half">
                                                    <label htmlFor="cs_company" className="label_text">회사명</label>
                                                    <input type="text" id="cs_company" name="company" placeholder="회사명을 입력해주세요." onChange={(e)=> {
                                                        templateParams.company = e.currentTarget.value;
                                                    }} />
                                                </div>
                                                :
                                                <div className="consult_cont col_half">
                                                    <label htmlFor="cs_company" className="label_text">Company</label>
                                                    <input type="text" id="cs_company" name="company" placeholder="" onChange={(e)=> {
                                                        templateParams.company = e.currentTarget.value;
                                                    }} />
                                                </div>
                                                }
                                                {lang ==="kr" ?
                                                <div className="consult_cont col_half">
                                                    <label htmlFor="cs_email" className="label_text">이메일 주소<em className="must star">*</em></label>
                                                    <input type="text" id="cs_email" name="user_email" placeholder="이메일 주소를 입력해주세요." onChange={(e)=> {
                                                        templateParams.user_email = e.currentTarget.value;
                                                    }} />
                                                </div>
                                                :
                                                <div className="consult_cont col_half">
                                                    <label htmlFor="cs_email" className="label_text">Email<em className="must star">*</em></label>
                                                    <input type="text" id="cs_email" name="user_email" placeholder="" onChange={(e)=> {
                                                        templateParams.user_email = e.currentTarget.value;
                                                    }} />
                                                </div>
                                                }
                                                {lang ==="kr" ?
                                                <div className="consult_cont">
                                                    <label htmlFor="cs_company" className="label_text">메세지<em className="must star">*</em></label>
                                                    <textarea id="" cols="30" rows="10" name="message" className="" onChange={(e)=> {
                                                        templateParams.message =  e.currentTarget.value;
                                                    }}></textarea>
                                                </div>
                                                :
                                                <div className="consult_cont">
                                                    <label htmlFor="cs_company" className="label_text">Message<em className="must star">*</em></label>
                                                    <textarea id="" cols="30" rows="10" name="message" className="" onChange={(e)=> {
                                                        templateParams.message =  e.currentTarget.value;
                                                    }}></textarea>
                                                </div>
                                                }
                                            </div>
                                            {lang ==="kr" ?
                                            <div className="consulting_box">
                                                <div className="btn_wrap">
                                                    { sended ? 
                                                    <p className="msg">제출이 완료되었습니다.</p>
                                                    : null }
                                                    <input type="submit" value="제출하기" className='btn btn_sm btn_blue_line'/>
                                                    {/* <button className="btn btn_sm_vw btn_blue_line">제출하기</button> */}
                                                </div>
                                            </div>
                                            :
                                            <div className="consulting_box">
                                                <div className="btn_wrap">
                                                    { sended ? 
                                                    <p className="msg">This message was successfully sent.</p>
                                                    : null }
                                                    <input type="submit" value="Send" className='btn btn_sm_vw btn_blue_line'/>
                                                    {/* <button className="btn btn_sm_vw btn_blue_line">제출하기</button> */}
                                                </div>
                                            </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer lang={lang} className="mt0"></Footer>
        </>
    )
}