import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga'
import { BrowserRouter } from 'react-router-dom';
import { MsalProviderToPCA } from "./components/auth/MSALModule";

let parts = window.location.host.split(".");
let subdomain = parts.length >= 3 ? parts[0] : "";
if (subdomain !== "download")
{
  //console.log("subdomain : ", subdomain);
  const root = ReactDOM.createRoot(document.getElementById('root'));
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);
  root.render(
    // <React.StrictMode>
    <MsalProviderToPCA>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProviderToPCA>
    // </React.StrictMode>
  );
  reportWebVitals();
}