/* eslint-disable */ 
import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import Footer from './Footer';


export default function Header() {
    let [openMenu,setOpenMenu] = useState(false);
    let [lang, setLang] = useState('kr');
    useEffect(() => {
        let href = window.location.href.split('//')[1];
        if (href.split('/')[1]==="en") {
            setLang('en');
        }
        else {
            setLang('kr');
        }
        return () => {
        }
    }, []);
    return ( 
        <div className="header_wrap">
            <header className={openMenu ? "header main-header hamburger-open" : "header main-header"}>

                <nav className="header_nav wrap_padding">
                    <a href={"/"+lang} className="header_logo"><span className="blind-text">MOPIC</span></a>
                    <ul className="header_gnbmenu pc-ver">
                        <li><Link to={"/"+lang+"/product"}>B2B</Link></li>
                        <li><Link to={"/"+lang+"/company"}>Company</Link></li>
                        <li><Link to={"/"+lang+"/solution"}>Solution</Link></li>
                        <li><Link to={"/"+lang+"/business"}>Business</Link></li>
                        <li><Link to={"/"+lang+"/lightfield"}>LightField</Link></li>
                        <li><Link to={"/"+lang+"/casestudy/list"}>CaseStudy</Link></li>
                        <li><Link to={"/"+lang+"/news/list"}>News</Link></li>
                        <li><Link to={"/"+lang+"/support/list"}>Support</Link></li>
                    </ul>
                    <section className="topsearch-box">
                        <div className="utility-tab utility-lang">
                            <ul className="bubble-box bubble-box01">
                                <li><a href="/kr/" className={lang==="kr"? "on" : null}>KR</a></li>
                                <li><a href="/en/" className={lang==="en"? "on" : null}>EN</a></li>
                            </ul>
                        </div>
                    </section>
                    
                    <div className="gnb-area"> 
                    <button className="js-hamburger_open" onClick={()=>{
                        setOpenMenu(!openMenu);
                    }}>
                        {/* <i class="spf spf-hamburger_menu"></i> */}
                        <i className="line line1"></i>
                        <i className="line line2"></i>
                        <i className="line line3"></i>
                    </button>
                    </div>
                </nav>

                <div className="hamburger_menu-wrap">
                    <div className="hamburger-panel">
                        <div className="hamburger-body">
                            <nav>
                                <ul className="menu-list">
                                    <li><Link to={"/"+lang+"/product"} onClick={()=> {
                                        setOpenMenu(false);
                                    }}>B2B</Link></li>
                                    <li><Link to={"/"+lang+"/company"} onClick={()=> {
                                        setOpenMenu(false);
                                    }}>Company</Link></li>
                                    <li><Link to={"/"+lang+"/solution"} onClick={()=> {
                                        setOpenMenu(false);
                                    }}>Solution</Link></li>
                                    <li><Link to={"/"+lang+"/business"} onClick={()=> {
                                        setOpenMenu(false);
                                    }}>Business</Link></li>
                                    <li><Link to={"/"+lang+"/lightfield"} onClick={()=> {
                                        setOpenMenu(false);
                                    }}>LightField</Link></li>
                                    <li><Link to={"/"+lang+"/casestudy/list"} onClick={()=> {
                                        setOpenMenu(false);
                                    }}>CaseStudy</Link></li>
                                    <li><Link to={"/"+lang+"/news/list"} onClick={()=> {
                                        setOpenMenu(false);
                                    }}>News</Link></li>
                                    <li><Link to={"/"+lang+"/support/list"} onClick={()=> {
                                        setOpenMenu(false);
                                    }}>Support</Link></li>
                                </ul>
                                <div className="utility-tab utility-lang">
                                    <ul className="bubble-box bubble-box01">
                                        <li><a href="/kr/" className={lang==="kr"? "on" : null}>KR</a></li>
                                        <li><a href="/en/" className={lang==="en"? "on" : null}>EN</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="hamburger-footer">
                            <Footer lang={lang}></Footer>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    ); 
}; 