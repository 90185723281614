import React from 'react'; 
import { Link } from 'react-router-dom';


export default function Footer(prop) {
    let lang =prop.lang;
    return ( 
        <footer className={"footer " + (prop.className ? prop.className : "")}>
            <div className="innerfooter">
                <section className="flex_wrap footer_infobox">

                    {lang === "kr" ? 
                    <p className="foot-company">
                        주식회사 모픽 (MOPIC CO., LTD.)  
                    </p>
                    :
                    <p className="foot-company">
                        MOPIC CO., LTD.
                    </p>
                    }
                    {lang === "kr" ? 
                    <p className="foot-info">
                        <span>(14058) 경기도 안양시 동안구 벌말로 66 하이필드 지식산업센터 B동 429호 & 1104호-1105호 </span>
                        <span>T. 031-8084-9866</span>
                    </p>
                    :
                    <p className="foot-info">
                        <span>1104 & 1105 - B Building, 66 Beolmal-Ro, Dongan-Gu, Anyang-Si, Gyeonggi-Do 14058, Korea </span>
                    </p>
                    }
                    {lang === "kr" ? 
                    <p className="foot-copyright">
                        ©2022 by MOPIC Labs
                    </p>
                    :
                    <p className="foot-copyright">
                        ©2022 by MOPIC Labs
                    </p>
                    }
                </section>
                <section className="footer-util">
                    <ul className="snsbox_icon">
                        <li><Link to="" className="sns-icon sns-icon01" onClick={()=>{
                            window.open('https://www.facebook.com/MopicVR','_blank')
                        }}></Link></li>
                        <li><Link to="" className="sns-icon sns-icon02" onClick={()=>{
                            window.open('https://www.linkedin.com/company/13281995/','_blank')
                        }}></Link></li>
                        <li><Link to="" className="sns-icon sns-icon03" onClick={()=>{
                            window.open('https://www.youtube.com/channel/UCqWaRWhgoPbhn0g2w67isxA/videos','_blank')
                        }}></Link></li>
                    </ul>
                    {lang === "kr" ? 
                    <div className="btn_wrap">
                        <Link to="" className="btn btn_white_line btn_sm" onClick={()=>{
                            window.open('https://docs.google.com/forms/d/e/1FAIpQLSdSU2-M1wxgIf7SbtH5IgeKeT7EDmQ7wDcvWMlYYN-7ZiKMrQ/viewform','_blank')
                        }}>뉴스레터</Link>
                        <Link to="" className="btn btn_white_line btn_sm" onClick={()=>{
                            window.open('https://bedecked-dormouse-66e.notion.site/2023-MOPIC-25de2efc906f427eb82ccff8192ce350','_blank')
                        }}>채용공고</Link>
                    </div>
                    :
                    <div className="btn_wrap">
                        <Link to="" className="btn btn_white_line btn_sm" onClick={()=>{
                            window.open('https://docs.google.com/forms/d/e/1FAIpQLSdSU2-M1wxgIf7SbtH5IgeKeT7EDmQ7wDcvWMlYYN-7ZiKMrQ/viewform','_blank')
                        }}>Newsletter</Link>
                        <Link to="" className="btn btn_white_line btn_sm" onClick={()=>{
                            window.open('https://bedecked-dormouse-66e.notion.site/2023-MOPIC-25de2efc906f427eb82ccff8192ce350','_blank')
                        }}>Career</Link>
                    </div>
                    }
                </section>
            </div>
            <div className="top_wrap">
                <button className="btn_top"><span className="blind">TOP</span></button>
            </div>
        </footer>
    ); 
}; 