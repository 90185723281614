/* eslint-disable */ 
import React, { useEffect, useState } from 'react'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import axios from 'axios';
import APIURI from './APIURI';


export default function SlideList(prop) {
    
    let type = prop.type.split('_')[0];
    let lang = prop.lang;
    let admin = prop.type.indexOf("ADMIN") > -1;
    let that = this;
    useEffect(() => {
        searchList()
        return () => {
            
        }
    }, []);
    const decodeHtmlEntities = (html) => {
        const parser = new DOMParser();
        const decoded = parser.parseFromString(html, 'text/html').body.textContent;
        return decoded;
    };

    function devodeContent(_content) {
        return $(_content).text().replaceAll('<br>',' ').substring(0,150);
    }
    function toDigit(num) {
        return (num<10) ? "0"+num: num;
    }
    function searchList() {
        let _url =APIURI+type.toLowerCase()+'/'+lang.toLowerCase()+'/3';
        
        axios.get(_url)
        .then(function (response) {
            //console.log(response.data);
            setSlideList(response.data.reverse()); 
        })


    }

    let [slideList, setSlideList] = useState ([])

    function b64toBlob(b64Data, sliceSize=512) {
        const byteCharacters = atob(b64Data.replace(/^data:image\/[a-z]+;base64,/, ''));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/png' });
        return blob;
    }
    const downloadImage = async (container, id) => {
        const res = await axios.get(APIURI+'image/?id='+ id);
        
        if (!res?.data?.image) return;
        const url = window.URL.createObjectURL(b64toBlob(res.data.image));
        //$("."+container).attr('src', res.data.image);
        $("."+container).attr('src', url);
    }
    return (
        <Swiper
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={1}
            navigation
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
            {slideList.map((row, idx) => (
                <SwiperSlide  key={"slide" +idx}>
                    <Link to={ "/"+lang.toLowerCase()+"/"+prop.type.toLowerCase()+"/view/"+row.post_id}>
                        <div className="img_wrap">
                            <img className={"img"+row.post_image_filename} loading={downloadImage("img"+row.post_image_filename,row.post_image_filename)}  alt="" />
                            {/* <img src={row.post_image} alt="" /> */}
                        </div>
                        <div className={type ==="NEWS" ? "cont_wrap" : "cont_wrap" }>
                            <div className="cont_inner" >
                                <p className="title">{decodeHtmlEntities(row.post_title)}</p>
                                
                                {row.post_contents ? 
                                <p className="txt">{decodeHtmlEntities(devodeContent(row.post_contents))}</p>
                                : null
                                }
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
            ))}
                    
        </Swiper>
    )
}